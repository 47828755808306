.afiliados-header {
  background: $color-deg-azul;
  padding: 10rem 5rem;
  color: $color-background;
  @media screen and (max-width: 991px) {
    padding: 10rem 0 5rem 0
  }
  @media screen and (max-width: 575px) {
    padding: 6rem 0 4rem 0;
  }
  &__container {
    display: grid;
    grid-template-columns: 5fr 7fr;
    gap: 5rem;
    margin-bottom: $sp-2;
    @media screen and (min-width: 768px) and (max-width: 991px) {
      grid-template-columns: 1fr 5fr !important;
      gap: 2rem;
    }
    @media screen and (max-width: 767px) {
      grid-template-columns: 1fr;
      gap: 1rem;
    }
  }

  &__left {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    @media screen and (min-width: 768px) and (max-width: 991px) {
      grid-column: 2 / 3;
    }
    @media screen and (max-width: 767px) {
      grid-row: 2 / 3;
    }
  }

  &__title {
    color: $color-background;
    font-weight: 700;
  }

  &__description {
    margin-bottom: 1.5rem;
  }

  &__actions {
    span {
      font-weight: bold;
      font-size: 1.5rem;
      font-weight: bold;
      color: rgb(120, 0, 0);
    }
  }

  &__media {
    text-align: center;
    @media screen and (max-width: 767px) {
      grid-row: 1 / 2;
      text-align: left;
    }
    @media screen and (min-width: 768px) and (max-width: 991px) {
      grid-column: 1 / 2;
      grid-row: 1 / 2;
    }
    img {
      width: 50%;
      filter: drop-shadow(0px 0px 20px rgba(0,0,0,.2));
      @media screen and (min-width: 768px) and (max-width: 991px) {
        width: 100%;
        margin-top: .5rem;
      }
      @media screen and (max-width: 767px) {
        width: 50px;
        height: auto;
      }
    }
  }
}




.features {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 3rem;
  @media screen and (max-width: 991px) {
    grid-template-columns: 1fr 1fr;
  }
  @media screen and (max-width: 575px) {
    grid-template-columns: 1fr;
    gap: 2rem;
  }
  &__item {
  }

  &__icon {
    margin-bottom: 1rem;
    i {
      font-size: 3rem;
    }
  }

  &__content {
  }

  &__title {
    font-size: 1.2rem;
    font-weight: bold;
  }

  &__escription {
  }
}







.afiliados-steps {

  &__title {
  }

  &__container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 4rem;
    @media screen and (max-width: 991px) {
      grid-template-columns: 1fr ;
      gap: 2rem;
    }
    @media screen and (max-width: 575px) {
      grid-template-columns: 1fr;
      gap: 2rem;
    }
  }

  &__item {

  }

  &__number {
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    margin-bottom: 2rem;
    @media screen and (max-width: 991px) {
      margin-bottom: 1rem;
    }
    big {
      font-size: 9rem;
      font-weight: bold;
      margin-right: 1rem;
      line-height: .8;
      // color: $color-gris-02;
      color: $color-principal-dark;
      @media screen and (max-width: 575px) {
        font-size: 7rem;
      }
    }
    span {
      font-size: 1.8rem;
      line-height: 1.2;
      font-weight: 500;
  
    }
  }
  &__content {
    
  }
}





.t-comisiones {
  display: grid;
  grid-template-columns: 1fr 2fr;
  gap: 3rem;
  @media screen and (max-width: 991px) {
    grid-template-columns: 1fr;
  }
  &__left {
  }

  &__content {
  }
}




.comisiones {

  &__item {
    margin-bottom: 1rem;
    background-color: $color-gris-01;
    border-radius: 1rem;
    padding: 2rem;

    @media screen and (max-width: 767px) {
      border: 1px solid rgba($color: #000000, $alpha: 0.2);
    }

    & > * {

    }
  }


  &__body, 
  &__header {
    display: grid;
    grid-template-columns: 170px 1fr 100px 100px;
    gap: 2rem;
    @media screen and (max-width: 767px) {
      grid-template-columns: 1fr;
      gap: 0rem;
    }
  }



  &__header {
    .comisiones__label {
      border-bottom: 1px solid rgba($color: #000000, $alpha: 0.2);
      padding-bottom: 5px;
      @media screen and (max-width: 767px) {
        display: none;
      }
    }

  }


  &__body {
    .comisiones__label {
      @media screen and (min-width: 768px) {
        display: none;
      }
    }
    & > * {
      display: flex;
      flex-direction: column;
      justify-content: center;
      @media screen and (max-width: 767px) {
        padding-bottom: 1rem;
        margin-bottom: 1rem;
        border-bottom: 1px dashed rgba($color: #000000, $alpha: 0.3);
      }
    }
  }


  &__level {

    img {
      width: 40px;
      height: auto;
      margin-right: .5rem;
      @media screen and (max-width: 767px) {
        width: 60px;
      }
    }
    @media screen and (max-width: 767px) {
      font-size: 1.5rem;
    }

    .comisiones__label {
      @media screen and (max-width: 767px) {
        margin-bottom: 10px;
      }
    }
  }

  &__ventas {
    p {
      margin-bottom: 0;
    }
  }

  &__comision {
    span {
      font-weight: bold;
    }

  }

  &__referidos {
    span {
      font-weight: bold;
    }
  }
  &__label {
    font-weight: 700;
    font-size: .75rem;
    text-transform: uppercase;
    letter-spacing: 0px;
    margin-bottom: 1rem;
    @media screen and (max-width: 767px) {
      margin-bottom: .3rem;
      color: $color-principal-dark;
    }
  }
}
