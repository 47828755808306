// .woocommerce-store-notice.demo_store {
//   background-color: rgba(158, 0, 0, 0.841);
//   color: #fff;
//   position: fixed;
//   top: 0;
//   color: #fff;
//   text-align: center;
//   padding-top: 0.5rem;
//   padding-bottom: 0.5rem;
//   font-size: 0.85rem;
//   z-index: 100;
//   width: 100%;
//   box-shadow: 0 0 20px rgba($color: #000000, $alpha: 0.6);
//   a {
//     background-color: $color-background;
//     padding: 2px 5px;
//     display: inline-block;
//     border-radius: 4px;
//     color: $color-gris-03;
//   }
// }

.wpfront-notification-bar {
  .wpfront-close {
    top: 10px !important;
  }
}

.page-woocommerce {
  padding-top: 2rem;
  padding-bottom: 4rem;
  @media screen and (max-width: 575px) {
    padding-top: 0;
  }
  &--product {
    padding-top: 0;
    // background-color: $color-background;
  }
}

ul.products {
  padding-left: 0;
  list-style: none;
  display: grid;
  width: 100%;
  margin-top: 1rem;
  li.product {
    // padding: 1rem;
    background-color: $color-background;
    border-radius: $border-radius-min;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @media screen and (max-width: 575px) {
      // padding: .7rem
    }

    .button {
      background: $color-gris-04;
      border-color: $color-gris-04;
      border-width: 2px !important;
      color: $color-background;
      border-style: solid;
      @include input-buttons-sm;
      justify-content: center;
      align-items: center;
      width: 100%;
      text-align: center;
      transition: $trans;
      &:hover {
        .bi {
          transition: $trans;
          margin-right: 15px;
        }
      }
      .bi {
        margin-right: 5px;
        margin-bottom: 5px;
        transition: $trans;
      }
    }
    .added_to_cart {
    }
  }

  .product__cat {
    margin-bottom: $font-size / 3;
    color: $color-gris;
    font-size: 0.75rem;
    letter-spacing: 0.5px;
    // text-transform: uppercase;
    @media (max-width: 575px) {
      margin-bottom: 0.2rem !important;
    }
  }

  .woocommerce-loop-product__link {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 1rem;
    position: relative;
    @media (max-width: 575px) {
      align-items: flex-start;
    }
    .onsale {
      position: absolute;
      background-color: #ff0066;
      color: $color-background;
      padding: 4px 10px 5px;
      border-radius: 0 0 5px 5px;
      text-transform: uppercase;
      font-size: 0.8rem;
      font-weight: bold;
      letter-spacing: 1px;
    }
  }

  .woocommerce-loop-product__link .wp-post-image {
    width: 100%;
    height: auto;
    // border: 1px solid $color-gris-02;
    margin-bottom: 1rem;
    border-radius: 4px;
  }

  .attachment-woocommerce_thumbnail {
    width: 100% !important;
    height: auto;
    margin-bottom: 1rem;
    // border-radius: $border-radius-min;
    border: 1px solid rgba($color: #000000, $alpha: 0.1);
    @media (max-width: 575px) {
      margin-bottom: 0.5rem !important;
    }
  }

  .woocommerce-loop-product__title {
    font-size: 1rem;
    margin-top: 0;
    margin-bottom: 0;
    font-weight: 500;
    text-align: center;
    width: 80%;
    color: $color-gris-dark;
    font-weight: bold;
    @media (max-width: 575px) {
      width: 95%;
      line-height: 1.1rem;
      text-align: initial;
      font-size: 1rem;
    }
  }

  .price {
    .woocommerce-Price-amount {
      color: $color-gris;
      font-size: 0.85rem;
    }

    .woocommerce-Price-currencySymbol {
      font-size: $font-size / 1.5;
    }
  }
  .it-price-suffix {
    margin-left: 10px;
    font-size: 0.75rem;
    padding: 3px 7px;
    background-color: $color-gris-02;
    color: $color-gris-03;
    border-radius: 3px;
    &--empty {
      display: none;
    }
  }
}

.columns-4 {
  grid-template-columns: repeat(4, 1fr);
  gap: 4rem 2rem;
  @media (min-width: 768px) and (max-width: 991px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: 767px) {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 2rem;
  }

  @media (max-width: 480px) {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 1rem;
  }
}

.term-description {
  margin-bottom: 1rem;
  font-size: 0.85rem;
}

.woocommerce-result-count {
  display: inline-block;
  background-color: $color-gris-02;
  color: $color-background;
  padding: 1px 10px;
  font-size: $font-size-small;
  border-radius: 3px;
  @media (max-width: 575px) {
    font-size: $font-size-small;
    display: none;
  }
}

.woocommerce-ordering {
  display: inline-block;
  float: right;
  clear: both;
  @media (max-width: 991px) {
    float: none;
  }
  .orderby {
    padding: 0;
    border: 0;
    background-position: right 0px top -2px;
    padding-right: 2rem;
    option {
      text-align: center;
    }
  }
}

/* Paginador -------------------- */

.woocommerce-pagination {
  text-align: center;

  ul.page-numbers {
    list-style: none;
    display: inline-flex;
    justify-content: center;
    width: auto !important;
    border: 0px solid $color-principal;
    padding: 2px;
    border-radius: 3px;
  }

  li {
    display: inline-block;
  }

  li .page-numbers {
    padding: 10px 20px;
    background-color: $color-principal;
    color: #fff;
    margin: 2px;
    display: block;
    border-radius: 3px;
    font-size: 1.2rem;
  }

  li .page-numbers.current {
    background-color: $color-principal-dark;
  }
  li .page-numbers:hover {
    background-color: $color-principal-dark;
  }
}
