

.m-overlay {
  display:none;
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
  right: 0;
  background: $color-deg-azul;
  padding: 3rem;
  z-index: 1000;
  overflow-y: scroll;
  overscroll-behavior: contain;
  color: $color-background;
  @media screen and (max-width: 575px) {
    padding: 1rem;
  }
  &__content {
    display: flex;
    flex-direction: column;
    height: 100%;
    @media screen and (max-width: 575px) {
      padding: 0px 12px;
    }
    @media screen and (max-width: 375px) {
      
    }
  }
  &__header {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  &__logo {
    margin: 1rem 0 1rem 0;
    img {
      width: 250px;
      @media screen and (max-width: 575px) {
        width: 146px;
      }
    }

  }
  &__close {
    .bi {
      font-size: 2.5rem;
    }
  }
  &__menu {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    position: relative;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
  &__nav {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    padding-left: 0;
    list-style: none;
    margin-bottom: 0rem;
    @media screen and (max-width: 575px) {
      margin-bottom: 2rem;
    }
    @media screen and (max-width: 375px) {
      margin-bottom: 1rem;
    }

    li{
      a {
        color: $color-background;
        text-decoration: none;
        font-size: 1.8rem;
        font-weight: 700;
        padding: 5px 10px;
      }
    }
    
  }


  &__actions {
    display: flex;
    justify-content: center ;
    flex-wrap:  wrap;
    margin-bottom: 5rem;
    padding-bottom: 5rem;
    align-items: center;
    a {
      margin: 0 10px 10px 10px;
    }
  }
}