.footer {
  padding: 5rem 0;
  background-color: $color-gris-03;
  color: $color-background;
  a {
    color: $color-principal;
  }
  &__copy {
    text-align: center;
    @media screen and (max-width: 767px) {
      text-align: left;
    }
    &__copy {
      margin-bottom: .4rem;
      @media screen and (max-width: 575px) {
        font-size: $font-size-small;
      }
    }
    &__politica {
      margin-bottom: 1rem;
      @media screen and (max-width: 575px) {
        font-size: $font-size-small;
      }
    }
    &__developer {
      font-size: $font-size-smaller;
    }
  }
}

.g-widgets {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 3rem;
  margin-bottom: 5rem;
  @media screen and (max-width: 575px) {
    grid-template-columns: 1fr 1fr;
  }
  &__item {
    &:first-child {
      @media screen and (max-width: 575px) {
        display: none;
      }
    }
    &:nth-child(2) {
      @media screen and (max-width: 575px) {
        grid-column: 1 / 3;
      }
    }
  }
}

.logo-footer {
  width: 150px;
  height: auto;
}