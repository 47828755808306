.page-title {
  padding-top: 10rem;
  padding-bottom: 1rem;
  @media screen and (max-width: 575px) {
    padding-top: 6rem;
  }
  &__h {
    font-weight: bold;
    font-size: 2.8rem;
    @media screen and (max-width: 575px) {
      font-size: 1.9rem;
    }
  }
  &__breadcrumbs {
    font-size: $font-size-smaller;
    text-transform: uppercase;
    a {
      color: $color-principal;
      font-weight: 500;
    }
  }
}
