.header {
  padding: 2rem 0;
  position: fixed;
  z-index: 100;
  top: 0;
  width: 100%;
  transition: $trans;

  @media screen and (max-width: 767px) {
    padding: 1rem 0;
  }

  &--pages {
    background-color: $color-gris-03;
  }
  
  &--gradient {
    // background: linear-gradient(180deg, rgba(0,0,0,.3) 0%, rgba(0,0,0,0) 100%);
  }

  &--sticky {
    background-color: $color-gris-03;
    padding: .5rem 0;
    transition: $trans;
    box-shadow: $box-shadow;
    .header-logo {
      width: 60px;
      height: auto;
      transition: $trans;
    }

  }

  &__container {
    display: flex;
    @media screen and (max-width: 991px) {
      justify-content: space-between;
    }
  }

  &__logo {
    flex: 0 0 20%;
    display: flex;
    align-items: center;
    img {
      width: 100px;
      height: auto;
      transition: $trans;
      @media screen and (max-width: 575px) {
        width: 70px;
        height: auto;
      }
    }
  }

  &__nav {
    flex: 0 0 60%;
    display: flex;
    align-items: center;
    justify-content: center;
    @media screen and (max-width: 991px) {
      display: none;
    }
  }

  &__menu {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    li.menu-item {
      a {
        padding: 0 1rem;
        color: #fff;
      }
    }
  }

  &__right {
    flex: 0 0 20%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    i {
      color: $color-background;
      font-size: 2rem;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    &__cart {
      position: relative;
      i {
        font-size: 1.8rem;
      }
      .cart-count {
        background-color: $color-principal;
        color: $color-background;
        font-size: 11px;
        position: absolute;
        top: 1px;
        right: -3px;
        z-index: 2;
        display: inline-block;
        padding: 0px 4px;
        border-radius: 3px;

      }
    }
    &__menu {
      margin-left: 1.0rem;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}


.page-template-page-afiliados-landing {
  .header {
    background: none;
  }
  .header.header--sticky {
    background-color: $color-gris-03;
  }
}