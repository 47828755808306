@charset "UTF-8";
body {
  background-color: white;
  font-size: 1rem;
  color: #323232;
  font-weight: 400; }

a {
  text-decoration: none;
  color: #008cd6; }

.container {
  max-width: 1400px;
  margin: 0 auto;
  padding-left: 20px;
  padding-right: 20px; }
  @media screen and (min-width: 768px) and (max-width: 1440px) {
    .container {
      padding-left: 40px;
      padding-right: 40px; } }

.container--max {
  max-width: 1400px; }

.pad-b-min {
  padding-bottom: 2rem; }

.mar-b-min {
  margin-bottom: 2rem; }

.pad-b {
  padding-bottom: 4rem; }

.mar-b {
  margin-bottom: 4rem; }

.pad-b-max {
  padding-bottom: 8rem; }

.mar-b-max {
  margin-bottom: 8rem; }

.pad-t-min {
  padding-top: 2rem; }

.mar-t-min {
  margin-top: 2rem; }

.pad-t {
  padding-top: 4rem; }

.mar-t {
  margin-top: 4rem; }

.pad-t-max {
  padding-top: 8rem; }

.mar-t-max {
  margin-top: 8rem; }

.pad-min {
  padding: 2rem 0; }

.pad {
  padding: 4rem 0; }

.pad-max {
  padding: 8rem 0; }

.color-primario {
  background-color: #00beea; }

.color-secundario {
  background-color: #f08175; }

.bg-primario {
  background-color: #00beea; }

.bg-primario {
  background-color: #f08175; }

.bg-gris-light {
  background-color: #f7f7f7; }

/* SCSS Headers
--------------------------------------------------- */
.h2-header {
  font-size: 2.8rem;
  margin-bottom: 1.5rem;
  font-weight: bold; }
  @media screen and (max-width: 767px) {
    .h2-header {
      font-size: 2rem; } }

.h2-subheader {
  font-size: 1.3rem;
  color: #008cd6; }
  @media screen and (max-width: 767px) {
    .h2-subheader {
      font-size: 1rem; } }

.header {
  padding: 2rem 0;
  position: fixed;
  z-index: 100;
  top: 0;
  width: 100%;
  transition: 0.2s all ease-in-out; }
  @media screen and (max-width: 767px) {
    .header {
      padding: 1rem 0; } }
  .header--pages {
    background-color: #323232; }
  .header--sticky {
    background-color: #323232;
    padding: .5rem 0;
    transition: 0.2s all ease-in-out;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1); }
    .header--sticky .header-logo {
      width: 60px;
      height: auto;
      transition: 0.2s all ease-in-out; }
  .header__container {
    display: flex; }
    @media screen and (max-width: 991px) {
      .header__container {
        justify-content: space-between; } }
  .header__logo {
    flex: 0 0 20%;
    display: flex;
    align-items: center; }
    .header__logo img {
      width: 100px;
      height: auto;
      transition: 0.2s all ease-in-out; }
      @media screen and (max-width: 575px) {
        .header__logo img {
          width: 70px;
          height: auto; } }
  .header__nav {
    flex: 0 0 60%;
    display: flex;
    align-items: center;
    justify-content: center; }
    @media screen and (max-width: 991px) {
      .header__nav {
        display: none; } }
  .header__menu {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex; }
    .header__menu li.menu-item a {
      padding: 0 1rem;
      color: #fff; }
  .header__right {
    flex: 0 0 20%;
    display: flex;
    justify-content: flex-end;
    align-items: center; }
    .header__right i {
      color: white;
      font-size: 2rem;
      display: flex;
      justify-content: center;
      align-items: center; }
    .header__right__cart {
      position: relative; }
      .header__right__cart i {
        font-size: 1.8rem; }
      .header__right__cart .cart-count {
        background-color: #00beea;
        color: white;
        font-size: 11px;
        position: absolute;
        top: 1px;
        right: -3px;
        z-index: 2;
        display: inline-block;
        padding: 0px 4px;
        border-radius: 3px; }
    .header__right__menu {
      margin-left: 1.0rem;
      display: flex;
      justify-content: center;
      align-items: center; }

.page-template-page-afiliados-landing .header {
  background: none; }

.page-template-page-afiliados-landing .header.header--sticky {
  background-color: #323232; }

.footer {
  padding: 5rem 0;
  background-color: #323232;
  color: white; }
  .footer a {
    color: #00beea; }
  .footer__copy {
    text-align: center; }
    @media screen and (max-width: 767px) {
      .footer__copy {
        text-align: left; } }
    .footer__copy__copy {
      margin-bottom: .4rem; }
      @media screen and (max-width: 575px) {
        .footer__copy__copy {
          font-size: 0.85rem; } }
    .footer__copy__politica {
      margin-bottom: 1rem; }
      @media screen and (max-width: 575px) {
        .footer__copy__politica {
          font-size: 0.85rem; } }
    .footer__copy__developer {
      font-size: 0.7rem; }

.g-widgets {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 3rem;
  margin-bottom: 5rem; }
  @media screen and (max-width: 575px) {
    .g-widgets {
      grid-template-columns: 1fr 1fr; } }
  @media screen and (max-width: 575px) {
    .g-widgets__item:first-child {
      display: none; } }
  @media screen and (max-width: 575px) {
    .g-widgets__item:nth-child(2) {
      grid-column: 1 / 3; } }

.logo-footer {
  width: 150px;
  height: auto; }

.m-overlay {
  display: none;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: linear-gradient(45deg, #00beea 0%, #008cd6 100%);
  padding: 3rem;
  z-index: 1000;
  overflow-y: scroll;
  overscroll-behavior: contain;
  color: white; }
  @media screen and (max-width: 575px) {
    .m-overlay {
      padding: 1rem; } }
  .m-overlay__content {
    display: flex;
    flex-direction: column;
    height: 100%; }
    @media screen and (max-width: 575px) {
      .m-overlay__content {
        padding: 0px 12px; } }
  .m-overlay__header {
    display: flex;
    justify-content: flex-end;
    align-items: center; }
  .m-overlay__logo {
    margin: 1rem 0 1rem 0; }
    .m-overlay__logo img {
      width: 250px; }
      @media screen and (max-width: 575px) {
        .m-overlay__logo img {
          width: 146px; } }
  .m-overlay__close .bi {
    font-size: 2.5rem; }
  .m-overlay__menu {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    position: relative;
    margin-top: 1rem;
    margin-bottom: 1rem; }
  .m-overlay__nav {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    padding-left: 0;
    list-style: none;
    margin-bottom: 0rem; }
    @media screen and (max-width: 575px) {
      .m-overlay__nav {
        margin-bottom: 2rem; } }
    @media screen and (max-width: 375px) {
      .m-overlay__nav {
        margin-bottom: 1rem; } }
    .m-overlay__nav li a {
      color: white;
      text-decoration: none;
      font-size: 1.8rem;
      font-weight: 700;
      padding: 5px 10px; }
  .m-overlay__actions {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin-bottom: 5rem;
    padding-bottom: 5rem;
    align-items: center; }
    .m-overlay__actions a {
      margin: 0 10px 10px 10px; }

.page-title {
  padding-top: 10rem;
  padding-bottom: 1rem; }
  @media screen and (max-width: 575px) {
    .page-title {
      padding-top: 6rem; } }
  .page-title__h {
    font-weight: bold;
    font-size: 2.8rem; }
    @media screen and (max-width: 575px) {
      .page-title__h {
        font-size: 1.9rem; } }
  .page-title__breadcrumbs {
    font-size: 0.7rem;
    text-transform: uppercase; }
    .page-title__breadcrumbs a {
      color: #00beea;
      font-weight: 500; }

.boton {
  padding: 0.7rem 1.7rem 0.6rem;
  font-size: 0.9rem;
  font-weight: 500;
  border-radius: 50px;
  display: inline-flex;
  align-items: center;
  text-decoration: none;
  border-style: solid;
  cursor: pointer;
  transition: 0.2s all ease-in-out; }
  .boton i {
    display: inline-flex;
    align-items: center;
    margin-right: 10px; }
  .boton:hover {
    transition: 0.2s all ease-in-out;
    transform: translateY(-2px);
    box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.15); }

.boton--sm {
  padding: 0.3rem 0.9rem 0.3rem;
  font-size: 0.85rem; }

.boton--lg {
  padding: 1rem 2.2rem;
  font-size: 1.1rem; }

input[disabled=""].boton--principal {
  opacity: 0.5;
  color: white !important;
  transform: initial; }
  input[disabled=""].boton--principal:hover {
    box-shadow: initial; }

.boton--principal {
  color: white;
  background: linear-gradient(45deg, #00beea 0%, #008cd6 100%);
  padding: 0.825rem 1.7rem 0.825rem;
  border: none; }
  .boton--principal:hover {
    color: white;
    background: linear-gradient(45deg, #00beea 0%, #008cd6 100%);
    text-decoration: none; }

.boton--principal-outline {
  color: #323232;
  background-color: initial;
  border-color: #00beea;
  padding: 0.7rem 1.7rem 0.6rem;
  border-width: 0.125rem; }
  .boton--principal-outline:hover {
    color: white;
    background-color: #00beea;
    border-color: #00beea;
    text-decoration: none; }

.boton--blanco {
  color: #323232;
  background-color: white;
  border-color: white;
  padding: 0.825rem 1.7rem 0.825rem;
  border: none; }
  .boton--blanco:hover {
    color: #151515;
    text-decoration: none; }

input[disabled=""].boton--principal {
  background-color: #f7f7f7;
  border-color: #f7f7f7;
  color: #ababab; }

.boton--outline-gris {
  color: #00beea;
  background-color: initial;
  border-color: #323232; }
  .boton--outline-gris:hover {
    color: #f08175;
    background-color: #323232;
    border-color: #323232;
    text-decoration: none; }

.boton--secundario {
  color: #00beea;
  background-color: #f08175;
  border-color: #f08175; }
  .boton--secundario:hover {
    color: white;
    background-color: #00beea;
    border-color: #00beea;
    text-decoration: none; }

.boton--alternativo {
  color: #323232;
  background-color: #ff9500;
  border-color: #ff9500; }
  .boton--alternativo:hover {
    color: #151515;
    background-color: #cc7700;
    border-color: #cc7700;
    text-decoration: none; }

.boton--gris {
  color: #323232;
  background-color: #f7f7f7;
  border-color: #f7f7f7; }
  .boton--gris:hover {
    color: #151515;
    background-color: #dedede;
    border-color: #dedede;
    text-decoration: none; }

.cta-home {
  background-color: #323232;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center; }
  .cta-home__container {
    padding: 6rem 1.2rem;
    max-width: 600px !important;
    margin-left: auto;
    margin-right: auto; }
    @media screen and (max-width: 575px) {
      .cta-home__container {
        padding: 3rem 1.2rem; } }
    .cta-home__container h2 {
      font-weight: bold; }
    .cta-home__container p {
      color: #ababab; }
      @media screen and (max-width: 767px) {
        .cta-home__container p {
          font-size: 0.85rem; } }
  .cta-home__actions {
    margin-top: 2rem; }
    @media screen and (max-width: 575px) {
      .cta-home__actions .boton {
        margin-bottom: 10px; } }

.cta-footer {
  padding: 8rem 0;
  background: linear-gradient(45deg, #00beea 0%, #008cd6 100%);
  color: white; }
  @media screen and (max-width: 575px) {
    .cta-footer {
      padding: 4rem 0; } }
  .cta-footer__container {
    display: flex;
    justify-content: space-evenly; }
    @media screen and (max-width: 767px) {
      .cta-footer__container {
        flex-direction: column; } }
    .cta-footer__container h2 {
      font-size: 2rem;
      font-weight: bold; }
      @media screen and (max-width: 575px) {
        .cta-footer__container h2 {
          font-size: 1.5rem; } }
    @media screen and (max-width: 575px) {
      .cta-footer__container p {
        font-size: 0.85rem; } }
  .cta-footer__left {
    flex: 0 0 30%; }
    @media screen and (max-width: 991px) {
      .cta-footer__left {
        flex: 0 0 40%; } }
    @media screen and (max-width: 767px) {
      .cta-footer__left {
        margin-bottom: 3rem; } }
  .cta-footer__right {
    flex: 0 0 30%; }
    @media screen and (max-width: 991px) {
      .cta-footer__right {
        flex: 0 0 40%; } }

.widget__title {
  text-transform: uppercase;
  margin-bottom: 1rem;
  font-size: 1rem;
  margin-bottom: 1.5rem;
  letter-spacing: .5px;
  font-weight: bold; }

.widget--footer p, .widget--footer a {
  font-size: 0.85rem; }

.widget--footer a {
  color: #00beea; }

.widget--footer ul.menu {
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }
  .widget--footer ul.menu .menu-item a {
    color: #ababab; }

.wpcf7 {
  margin: 1.5rem 0; }
  .wpcf7 .form-group {
    margin-bottom: 1rem; }
  .wpcf7 .form-label {
    font-size: 0.85rem;
    font-weight: 500;
    margin-bottom: .3rem; }
  .wpcf7 .form-control {
    width: 100%; }
  .wpcf7 .wpcf7-not-valid-tip {
    font-size: 12px;
    color: red; }
  .wpcf7 a {
    color: #008cd6; }

.form-control {
  padding: .775rem 1rem;
  font-size: .85rem;
  box-shadow: none; }

.wpcf7-acceptance {
  font-size: 0.7rem;
  color: #323232;
  color: rgba(0, 0, 0, 0.4); }

.wpcf7-response-output {
  margin: 2em 0.5em 1em;
  padding: 0.5em 1em;
  border-radius: 8px; }

.wpcf7-mail-sent-ok {
  border: 2px solid #398f14;
  background-color: #398f14;
  color: white; }

.wpcf7-mail-sent-ng {
  border: 2px solid #ff0000;
  background-color: #ff0000;
  color: white; }

.wpcf7-spam-blocked {
  border: 2px solid #ffa500;
  background-color: #ffa500; }

.wpcf7-acceptance-missig {
  border: 2px solid #ffa500 !important;
  background-color: #ffa500 !important; }

.page__entry h2, .page__entry h3, .page__entry h4, .page__entry h5 {
  margin-bottom: 1.5rem;
  font-weight: bold; }

.page__entry h3 {
  color: #323232; }

.page__entry p, .page__entry ul, .page__entry ol {
  margin-bottom: 1.5rem; }

.page__entry a {
  color: #00beea; }

.page-id-59,
.page-id-60 {
  background: linear-gradient(45deg, #00beea 0%, #008cd6 100%); }
  .page-id-59 .page-title,
  .page-id-60 .page-title {
    text-align: center;
    color: white; }
  .page-id-59 .page-title__breadcrumbs a,
  .page-id-60 .page-title__breadcrumbs a {
    color: white; }

.uap-user-page-top-wrapper {
  padding-left: 0 !important;
  padding-right: 0 !important; }
  @media screen and (max-width: 575px) {
    .uap-user-page-top-wrapper .uap-left-side {
      width: 30% !important;
      max-width: 30% !important; } }
  @media screen and (max-width: 575px) {
    .uap-user-page-top-wrapper .uap-middle-side {
      width: 70% !important;
      max-width: 70% !important; } }

.uap-ap-theme-3 .uap-user-page-content {
  padding-left: 0 !important;
  padding-right: 0 !important; }

.uap-register-14,
.uap-login-template-13 {
  background-color: white !important;
  padding: 2rem !important;
  box-shadow: 0 0 40px rgba(0, 0, 0, 0.1);
  border-radius: 4px; }
  .uap-register-14 input[type="password"],
  .uap-register-14 input[type="text"],
  .uap-login-template-13 input[type="password"],
  .uap-login-template-13 input[type="text"] {
    box-shadow: none;
    border-radius: 4px !important; }
  .uap-register-14 .uap-login-link,
  .uap-register-14 input[type="submit"],
  .uap-login-template-13 .uap-login-link,
  .uap-login-template-13 input[type="submit"] {
    border-radius: 50px !important;
    border-bottom: none !important; }
  .uap-register-14 .uap-form-input-remember,
  .uap-login-template-13 .uap-form-input-remember {
    height: 10px !important;
    width: 10px !important;
    box-shadow: none !important;
    border-radius: 3px !important;
    margin-left: 0 !important; }
  .uap-register-14 .selection .select2-selection__rendered,
  .uap-login-template-13 .selection .select2-selection__rendered {
    padding: 5px 14px !important; }

.uap-login-error {
  text-align: center;
  padding-top: 1rem;
  font-size: 0.85rem;
  color: red; }

.hero {
  height: 100vh;
  background-color: #C8C8C8;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center; }
  .hero__cercle {
    width: 50%;
    top: 0;
    bottom: 0;
    position: absolute; }
    .hero__cercle--left {
      background: url("../assets/site/bg-hero-left.svg") no-repeat center left;
      background-size: auto 100%;
      left: 0;
      z-index: 1; }
      @media screen and (max-width: 991px) {
        .hero__cercle--left {
          background-position: center right; } }
    .hero__cercle--right {
      background: url("../assets/site/bg-hero-right.svg") no-repeat center right;
      background-size: auto 100%;
      right: 0;
      z-index: 2; }
      @media screen and (max-width: 991px) {
        .hero__cercle--right {
          background-position: center left; } }
  .hero__container {
    position: relative;
    padding-top: 4rem;
    z-index: 10;
    max-width: 90%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
    align-items: center; }

.owl-carousel {
  max-width: 600px !important;
  margin-left: auto;
  margin-right: auto; }
  .owl-carousel .owl-nav .owl-prev,
  .owl-carousel .owl-nav .owl-next {
    width: 50px;
    height: 55px;
    position: absolute;
    top: 30%;
    display: block !important;
    border: 0px solid black; }
    .owl-carousel .owl-nav .owl-prev::after,
    .owl-carousel .owl-nav .owl-next::after {
      content: '';
      position: absolute;
      width: 100px;
      height: 1px;
      background-color: white;
      display: block;
      top: 1.8rem; }
    .owl-carousel .owl-nav .owl-prev:hover,
    .owl-carousel .owl-nav .owl-next:hover {
      background-color: rgba(0, 0, 0, 0) !important; }
    .owl-carousel .owl-nav .owl-prev .ico-chevron,
    .owl-carousel .owl-nav .owl-next .ico-chevron {
      width: 55px;
      height: 55px;
      display: block; }
      .owl-carousel .owl-nav .owl-prev .ico-chevron--left,
      .owl-carousel .owl-nav .owl-next .ico-chevron--left {
        background: url("../assets/ico/ico-chevron-left.svg") no-repeat center center; }
      .owl-carousel .owl-nav .owl-prev .ico-chevron--right,
      .owl-carousel .owl-nav .owl-next .ico-chevron--right {
        background: url("../assets/ico/ico-chevron-right.svg") no-repeat center center; }
  .owl-carousel .owl-nav .owl-prev {
    left: -40%; }
    @media screen and (max-width: 991px) {
      .owl-carousel .owl-nav .owl-prev {
        left: -20%; } }
    .owl-carousel .owl-nav .owl-prev::after {
      left: 4rem; }
  .owl-carousel .owl-nav .owl-next {
    right: -35%; }
    @media screen and (max-width: 991px) {
      .owl-carousel .owl-nav .owl-next {
        right: -18%; } }
    .owl-carousel .owl-nav .owl-next::after {
      right: 3rem; }
  .owl-carousel__item {
    width: 100%; }
  .owl-carousel__picture {
    position: relative; }
    .owl-carousel__picture > img {
      height: 100%;
      width: auto;
      z-index: 11;
      position: relative;
      filter: drop-shadow(0px 0px 0px rgba(0, 0, 0, 0.2)); }
  .owl-carousel__base {
    position: absolute;
    z-index: 10;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center; }
    .owl-carousel__base img {
      width: 70% !important; }
  .owl-carousel__description {
    text-align: center; }
    .owl-carousel__description h2 {
      text-transform: uppercase;
      font-weight: bold;
      font-size: 2.1rem; }
      .owl-carousel__description h2 a {
        color: #151515; }

.phome {
  max-width: 1600px;
  margin-left: auto;
  margin-right: auto; }
  .phome__item {
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: 1fr 1fr;
    grid-template-areas: "left right"; }
    @media screen and (max-width: 767px) {
      .phome__item {
        grid-template-columns: 1fr;
        grid-template-areas: "right" "left"; } }
    .phome__item:nth-child(2n+1) {
      grid-template-areas: "right left"; }
      @media screen and (max-width: 767px) {
        .phome__item:nth-child(2n+1) {
          grid-template-areas: "right" "left"; } }
  .phome__content {
    padding: 4rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    grid-area: left; }
    @media screen and (max-width: 991px) {
      .phome__content {
        padding: 3rem; } }
    @media screen and (max-width: 575px) {
      .phome__content {
        padding: 1rem 1.5rem 3.5rem; } }
  .phome__header {
    font-size: 5rem;
    font-weight: bold;
    margin-top: 0;
    margin-bottom: 1.5rem; }
    @media screen and (max-width: 1199px) {
      .phome__header {
        font-size: 3rem; } }
    @media screen and (max-width: 991px) {
      .phome__header {
        font-size: 3rem; } }
    @media screen and (max-width: 575px) {
      .phome__header {
        font-size: 2rem;
        margin-bottom: 1rem; } }
    .phome__header span {
      display: block;
      font-weight: lighter;
      font-size: 70%; }
  .phome__description {
    margin-bottom: 1rem; }
    @media screen and (max-width: 575px) {
      .phome__description {
        margin-bottom: 0; } }
    .phome__description p {
      color: #323232; }
      @media screen and (max-width: 767px) {
        .phome__description p {
          font-size: 0.85rem; } }
  .phome__picture {
    grid-area: right;
    margin-bottom: 0; }
    .phome__picture img {
      width: 100%;
      height: auto; }

.afiliados-header {
  background: linear-gradient(45deg, #00beea 0%, #008cd6 100%);
  padding: 10rem 5rem;
  color: white; }
  @media screen and (max-width: 991px) {
    .afiliados-header {
      padding: 10rem 0 5rem 0; } }
  @media screen and (max-width: 575px) {
    .afiliados-header {
      padding: 6rem 0 4rem 0; } }
  .afiliados-header__container {
    display: grid;
    grid-template-columns: 5fr 7fr;
    gap: 5rem;
    margin-bottom: 4rem; }
    @media screen and (min-width: 768px) and (max-width: 991px) {
      .afiliados-header__container {
        grid-template-columns: 1fr 5fr !important;
        gap: 2rem; } }
    @media screen and (max-width: 767px) {
      .afiliados-header__container {
        grid-template-columns: 1fr;
        gap: 1rem; } }
  .afiliados-header__left {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column; }
    @media screen and (min-width: 768px) and (max-width: 991px) {
      .afiliados-header__left {
        grid-column: 2 / 3; } }
    @media screen and (max-width: 767px) {
      .afiliados-header__left {
        grid-row: 2 / 3; } }
  .afiliados-header__title {
    color: white;
    font-weight: 700; }
  .afiliados-header__description {
    margin-bottom: 1.5rem; }
  .afiliados-header__actions span {
    font-weight: bold;
    font-size: 1.5rem;
    font-weight: bold;
    color: #780000; }
  .afiliados-header__media {
    text-align: center; }
    @media screen and (max-width: 767px) {
      .afiliados-header__media {
        grid-row: 1 / 2;
        text-align: left; } }
    @media screen and (min-width: 768px) and (max-width: 991px) {
      .afiliados-header__media {
        grid-column: 1 / 2;
        grid-row: 1 / 2; } }
    .afiliados-header__media img {
      width: 50%;
      filter: drop-shadow(0px 0px 20px rgba(0, 0, 0, 0.2)); }
      @media screen and (min-width: 768px) and (max-width: 991px) {
        .afiliados-header__media img {
          width: 100%;
          margin-top: .5rem; } }
      @media screen and (max-width: 767px) {
        .afiliados-header__media img {
          width: 50px;
          height: auto; } }

.features {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 3rem; }
  @media screen and (max-width: 991px) {
    .features {
      grid-template-columns: 1fr 1fr; } }
  @media screen and (max-width: 575px) {
    .features {
      grid-template-columns: 1fr;
      gap: 2rem; } }
  .features__icon {
    margin-bottom: 1rem; }
    .features__icon i {
      font-size: 3rem; }
  .features__title {
    font-size: 1.2rem;
    font-weight: bold; }

.afiliados-steps__container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 4rem; }
  @media screen and (max-width: 991px) {
    .afiliados-steps__container {
      grid-template-columns: 1fr;
      gap: 2rem; } }
  @media screen and (max-width: 575px) {
    .afiliados-steps__container {
      grid-template-columns: 1fr;
      gap: 2rem; } }

.afiliados-steps__number {
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  margin-bottom: 2rem; }
  @media screen and (max-width: 991px) {
    .afiliados-steps__number {
      margin-bottom: 1rem; } }
  .afiliados-steps__number big {
    font-size: 9rem;
    font-weight: bold;
    margin-right: 1rem;
    line-height: .8;
    color: #008cd6; }
    @media screen and (max-width: 575px) {
      .afiliados-steps__number big {
        font-size: 7rem; } }
  .afiliados-steps__number span {
    font-size: 1.8rem;
    line-height: 1.2;
    font-weight: 500; }

.t-comisiones {
  display: grid;
  grid-template-columns: 1fr 2fr;
  gap: 3rem; }
  @media screen and (max-width: 991px) {
    .t-comisiones {
      grid-template-columns: 1fr; } }

.comisiones__item {
  margin-bottom: 1rem;
  background-color: #f7f7f7;
  border-radius: 1rem;
  padding: 2rem; }
  @media screen and (max-width: 767px) {
    .comisiones__item {
      border: 1px solid rgba(0, 0, 0, 0.2); } }

.comisiones__body, .comisiones__header {
  display: grid;
  grid-template-columns: 170px 1fr 100px 100px;
  gap: 2rem; }
  @media screen and (max-width: 767px) {
    .comisiones__body, .comisiones__header {
      grid-template-columns: 1fr;
      gap: 0rem; } }

.comisiones__header .comisiones__label {
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  padding-bottom: 5px; }
  @media screen and (max-width: 767px) {
    .comisiones__header .comisiones__label {
      display: none; } }

@media screen and (min-width: 768px) {
  .comisiones__body .comisiones__label {
    display: none; } }

.comisiones__body > * {
  display: flex;
  flex-direction: column;
  justify-content: center; }
  @media screen and (max-width: 767px) {
    .comisiones__body > * {
      padding-bottom: 1rem;
      margin-bottom: 1rem;
      border-bottom: 1px dashed rgba(0, 0, 0, 0.3); } }

.comisiones__level img {
  width: 40px;
  height: auto;
  margin-right: .5rem; }
  @media screen and (max-width: 767px) {
    .comisiones__level img {
      width: 60px; } }

@media screen and (max-width: 767px) {
  .comisiones__level {
    font-size: 1.5rem; } }

@media screen and (max-width: 767px) {
  .comisiones__level .comisiones__label {
    margin-bottom: 10px; } }

.comisiones__ventas p {
  margin-bottom: 0; }

.comisiones__comision span {
  font-weight: bold; }

.comisiones__referidos span {
  font-weight: bold; }

.comisiones__label {
  font-weight: 700;
  font-size: .75rem;
  text-transform: uppercase;
  letter-spacing: 0px;
  margin-bottom: 1rem; }
  @media screen and (max-width: 767px) {
    .comisiones__label {
      margin-bottom: .3rem;
      color: #008cd6; } }

.l-header {
  padding: 9rem 0rem 3rem; }
  @media screen and (max-width: 575px) {
    .l-header {
      padding-top: 6rem;
      padding-bottom: 2rem; } }
  .l-header__container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 3rem; }
    @media screen and (max-width: 575px) {
      .l-header__container {
        grid-template-columns: 1fr;
        gap: 0; } }
  .l-header__picture {
    display: flex;
    justify-content: center;
    align-items: center; }
    @media screen and (max-width: 767px) {
      .l-header__picture {
        justify-content: left; } }
    .l-header__picture img {
      width: 80%;
      height: auto; }
    @media screen and (max-width: 767px) {
      .l-header__picture {
        width: 70%; } }
  .l-header__content {
    display: flex;
    flex-direction: column;
    justify-content: center; }
  .l-header__title {
    font-size: 3rem;
    margin-bottom: 0; }
    @media screen and (max-width: 575px) {
      .l-header__title {
        font-size: 1.5rem; } }
  .l-header__marca {
    font-size: 4.125rem;
    font-weight: bold;
    margin-bottom: 1rem; }
    @media screen and (max-width: 575px) {
      .l-header__marca {
        font-size: 2rem; } }
  .l-header__precio {
    font-size: 1.25rem; }
    .l-header__precio > span {
      font-weight: bold;
      padding-left: 1rem; }
  .l-header__duracion {
    margin-bottom: 2rem;
    font-size: 1.25rem; }
    @media screen and (max-width: 575px) {
      .l-header__duracion {
        font-size: 0.85rem; } }
    .l-header__duracion mark {
      background-color: #ffe34e;
      border-radius: 3px; }

.l-description {
  padding: 5rem 0; }
  @media screen and (max-width: 575px) {
    .l-description {
      padding: 3rem 0; } }
  .l-description__content {
    text-align: center; }
  .l-description__titulo {
    color: #00beea;
    font-size: 3.1rem; }
    @media screen and (max-width: 575px) {
      .l-description__titulo {
        font-size: 1.2rem; } }
  .l-description__marca {
    font-size: 5rem;
    font-weight: bold; }
    @media screen and (max-width: 575px) {
      .l-description__marca {
        font-size: 1.8rem; } }
  .l-description__descripcion {
    margin-bottom: 2rem;
    width: 80%;
    font-size: 1.2rem;
    margin-left: auto;
    margin-right: auto;
    color: #323232; }
    @media screen and (max-width: 575px) {
      .l-description__descripcion {
        width: 100%;
        font-size: 0.85rem; } }

.l-extras {
  padding: 4rem 0; }
  @media screen and (max-width: 575px) {
    .l-extras {
      padding: 2rem 0 4rem; } }
  .l-extras__content {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 3rem; }
    @media screen and (max-width: 575px) {
      .l-extras__content {
        grid-template-columns: 1fr;
        gap: 0; } }
  .l-extras__pic {
    text-align: center; }
    .l-extras__pic img {
      width: 80%;
      height: auto; }
      @media screen and (max-width: 575px) {
        .l-extras__pic img {
          width: 70%; } }
  .l-extras__right {
    display: flex;
    justify-content: center;
    flex-direction: column; }
  .l-extras__marca {
    color: #00beea;
    font-size: 1.2rem; }
  .l-extras__titulo {
    font-weight: bold;
    font-size: 2.5; }
  .l-extras__descripcion {
    font-size: 20px;
    color: #323232; }
    @media screen and (max-width: 575px) {
      .l-extras__descripcion {
        font-size: 0.85rem; } }

.l-galeria {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 5px;
  margin-bottom: 5px; }
  @media screen and (max-width: 575px) {
    .l-galeria {
      grid-template-columns: repeat(2, 1fr); } }
  .l-galeria__item img {
    width: 100%;
    height: auto; }
  @media screen and (max-width: 575px) {
    .l-galeria__item:first-child {
      grid-column: 1 / 3; } }

.cta-landing {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 6rem;
  color: white;
  margin-bottom: 5px; }
  @media screen and (max-width: 575px) {
    .cta-landing {
      grid-template-columns: 1fr; } }
  @media screen and (max-width: 575px) {
    .cta-landing__picure {
      display: none; } }
  .cta-landing__content {
    display: flex;
    flex-direction: column;
    justify-content: center; }
  .cta-landing__comprar {
    font-weight: 700; }
    @media screen and (max-width: 575px) {
      .cta-landing__comprar {
        display: none; } }
  .cta-landing__desc {
    font-size: 2rem; }
  .cta-landing__titulo {
    font-size: 7.6875rem;
    font-weight: bold;
    white-space: nowrap;
    margin-left: -300px;
    position: relative; }
    @media screen and (max-width: 575px) {
      .cta-landing__titulo {
        font-size: 3rem;
        color: #00beea;
        position: initial;
        margin-left: 0; } }
  .cta-landing__actions {
    display: flex;
    margin-top: 3rem; }
    @media screen and (max-width: 575px) {
      .cta-landing__actions {
        margin-top: 1rem; } }
  .cta-landing__logo {
    margin-right: 2rem; }
    @media screen and (max-width: 575px) {
      .cta-landing__logo {
        display: flex;
        align-items: center; } }
    .cta-landing__logo img {
      width: 130px;
      height: auto; }
      @media screen and (max-width: 575px) {
        .cta-landing__logo img {
          width: 100px; } }
  .cta-landing__price {
    margin-bottom: 1rem;
    font-size: 1.5rem; }

.t-envios {
  margin-bottom: 3rem; }
  .t-envios__envio {
    display: grid;
    grid-template-columns: 200px 250px 1fr;
    border: 10px solid rgba(0, 0, 0, 0.2);
    padding: 2rem;
    gap: 2rem;
    margin-bottom: 2rem;
    border-radius: 2rem; }
    @media (max-width: 767px) {
      .t-envios__envio {
        margin-bottom: 1rem;
        padding: 1.5rem;
        gap: 1rem; } }
    @media (max-width: 767px) {
      .t-envios__envio {
        grid-template-columns: 1fr; } }
  .t-envios__agencia {
    display: flex;
    justify-content: center;
    align-items: center; }
    @media (max-width: 767px) {
      .t-envios__agencia {
        justify-content: flex-start;
        align-items: flex-start; } }
  .t-envios__agencia img {
    width: 150px;
    height: auto; }
  .t-envios__zona {
    text-transform: uppercase;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #151515;
    background-color: rgba(0, 0, 0, 0.05);
    border-radius: 1rem;
    text-align: center; }
    @media (max-width: 767px) {
      .t-envios__zona {
        text-align: left;
        display: block;
        padding: 8px;
        font-size: 0.9rem;
        border-radius: 4px; } }
  .t-envios__precio span {
    font-size: 2rem;
    font-weight: bold;
    color: #151515; }
  .t-envios__precio div {
    font-size: 0.85rem; }

.t-pagos {
  margin-bottom: 3rem; }
  .t-pagos__item {
    display: grid;
    grid-template-columns: 1fr 2fr;
    border: 10px solid rgba(0, 0, 0, 0.2);
    padding: 2rem;
    gap: 2rem;
    margin-bottom: 2rem;
    border-radius: 2rem; }
    @media (max-width: 767px) {
      .t-pagos__item {
        margin-bottom: 1rem;
        padding: 1.5rem;
        gap: 1rem; } }
    @media (max-width: 575px) {
      .t-pagos__item {
        grid-template-columns: 1fr;
        padding: 0;
        border: 0;
        margin-bottom: 2rem;
        gap: .5rem; } }
  .t-pagos__metodo {
    font-weight: bold;
    font-size: 2rem;
    color: #151515; }
    @media (max-width: 575px) {
      .t-pagos__metodo {
        color: #00beea;
        font-size: 1.6rem;
        margin-bottom: 0.0rem; } }
  .t-pagos__subtitulo {
    font-weight: bold;
    font-size: 1.1rem;
    color: #008cd6;
    text-transform: uppercase;
    margin-bottom: 1rem; }
    @media (max-width: 575px) {
      .t-pagos__subtitulo {
        color: #151515;
        font-size: 1rem; } }
  .t-pagos__descripcion {
    font-size: 0.9rem; }
    .t-pagos__descripcion ul,
    .t-pagos__descripcion ol, .t-pagos__descripcion li,
    .t-pagos__descripcion ul li,
    .t-pagos__descripcion p {
      margin-bottom: 0.5rem !important; }
    .t-pagos__descripcion strong {
      color: #151515; }

.delivery {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 5rem; }
  @media screen and (max-width: 991px) {
    .delivery {
      grid-template-columns: 1fr;
      gap: 2rem; } }
  .delivery h4 {
    color: #008cd6;
    font-weight: bold;
    margin-bottom: 1.5rem; }

.wpfront-notification-bar .wpfront-close {
  top: 10px !important; }

.page-woocommerce {
  padding-top: 2rem;
  padding-bottom: 4rem; }
  @media screen and (max-width: 575px) {
    .page-woocommerce {
      padding-top: 0; } }
  .page-woocommerce--product {
    padding-top: 0; }

ul.products {
  padding-left: 0;
  list-style: none;
  display: grid;
  width: 100%;
  margin-top: 1rem; }
  ul.products li.product {
    background-color: white;
    border-radius: 0.33333rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between; }
    ul.products li.product .button {
      background: #151515;
      border-color: #151515;
      border-width: 2px !important;
      color: white;
      border-style: solid;
      padding: .55rem 1.2rem;
      letter-spacing: 0px;
      font-size: .9rem;
      cursor: pointer;
      transition: 0.2s all ease-in-out;
      justify-content: center;
      align-items: center;
      width: 100%;
      text-align: center;
      transition: 0.2s all ease-in-out; }
      ul.products li.product .button:hover .bi {
        transition: 0.2s all ease-in-out;
        margin-right: 15px; }
      ul.products li.product .button .bi {
        margin-right: 5px;
        margin-bottom: 5px;
        transition: 0.2s all ease-in-out; }
  ul.products .product__cat {
    margin-bottom: 0.33333rem;
    color: #323232;
    font-size: 0.75rem;
    letter-spacing: 0.5px; }
    @media (max-width: 575px) {
      ul.products .product__cat {
        margin-bottom: 0.2rem !important; } }
  ul.products .woocommerce-loop-product__link {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 1rem;
    position: relative; }
    @media (max-width: 575px) {
      ul.products .woocommerce-loop-product__link {
        align-items: flex-start; } }
    ul.products .woocommerce-loop-product__link .onsale {
      position: absolute;
      background-color: #ff0066;
      color: white;
      padding: 4px 10px 5px;
      border-radius: 0 0 5px 5px;
      text-transform: uppercase;
      font-size: 0.8rem;
      font-weight: bold;
      letter-spacing: 1px; }
  ul.products .woocommerce-loop-product__link .wp-post-image {
    width: 100%;
    height: auto;
    margin-bottom: 1rem;
    border-radius: 4px; }
  ul.products .attachment-woocommerce_thumbnail {
    width: 100% !important;
    height: auto;
    margin-bottom: 1rem;
    border: 1px solid rgba(0, 0, 0, 0.1); }
    @media (max-width: 575px) {
      ul.products .attachment-woocommerce_thumbnail {
        margin-bottom: 0.5rem !important; } }
  ul.products .woocommerce-loop-product__title {
    font-size: 1rem;
    margin-top: 0;
    margin-bottom: 0;
    font-weight: 500;
    text-align: center;
    width: 80%;
    color: #151515;
    font-weight: bold; }
    @media (max-width: 575px) {
      ul.products .woocommerce-loop-product__title {
        width: 95%;
        line-height: 1.1rem;
        text-align: initial;
        font-size: 1rem; } }
  ul.products .price .woocommerce-Price-amount {
    color: #323232;
    font-size: 0.85rem; }
  ul.products .price .woocommerce-Price-currencySymbol {
    font-size: 0.66667rem; }
  ul.products .it-price-suffix {
    margin-left: 10px;
    font-size: 0.75rem;
    padding: 3px 7px;
    background-color: #ababab;
    color: #323232;
    border-radius: 3px; }
    ul.products .it-price-suffix--empty {
      display: none; }

.columns-4 {
  grid-template-columns: repeat(4, 1fr);
  gap: 4rem 2rem; }
  @media (min-width: 768px) and (max-width: 991px) {
    .columns-4 {
      grid-template-columns: repeat(2, 1fr); } }
  @media (max-width: 767px) {
    .columns-4 {
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 2rem; } }
  @media (max-width: 480px) {
    .columns-4 {
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 1rem; } }

.term-description {
  margin-bottom: 1rem;
  font-size: 0.85rem; }

.woocommerce-result-count {
  display: inline-block;
  background-color: #ababab;
  color: white;
  padding: 1px 10px;
  font-size: 0.85rem;
  border-radius: 3px; }
  @media (max-width: 575px) {
    .woocommerce-result-count {
      font-size: 0.85rem;
      display: none; } }

.woocommerce-ordering {
  display: inline-block;
  float: right;
  clear: both; }
  @media (max-width: 991px) {
    .woocommerce-ordering {
      float: none; } }
  .woocommerce-ordering .orderby {
    padding: 0;
    border: 0;
    background-position: right 0px top -2px;
    padding-right: 2rem; }
    .woocommerce-ordering .orderby option {
      text-align: center; }

/* Paginador -------------------- */
.woocommerce-pagination {
  text-align: center; }
  .woocommerce-pagination ul.page-numbers {
    list-style: none;
    display: inline-flex;
    justify-content: center;
    width: auto !important;
    border: 0px solid #00beea;
    padding: 2px;
    border-radius: 3px; }
  .woocommerce-pagination li {
    display: inline-block; }
  .woocommerce-pagination li .page-numbers {
    padding: 10px 20px;
    background-color: #00beea;
    color: #fff;
    margin: 2px;
    display: block;
    border-radius: 3px;
    font-size: 1.2rem; }
  .woocommerce-pagination li .page-numbers.current {
    background-color: #008cd6; }
  .woocommerce-pagination li .page-numbers:hover {
    background-color: #008cd6; }

div.product {
  display: grid;
  grid-template-columns: repeat(10, 1fr);
  grid-auto-rows: auto;
  column-gap: 3rem;
  row-gap: 4rem; }
  @media (max-width: 575px) {
    div.product {
      display: flex;
      flex-direction: column;
      row-gap: 1rem; } }
  div.product .woocommerce-product-gallery {
    grid-column: 1 / 6;
    grid-row: 1 / 2;
    position: relative; }
  div.product .summary {
    grid-column: 6 / 10;
    grid-row: 1 / 2; }
  div.product .woocommerce-tabs {
    grid-column: 1 / 6;
    grid-row: 2 / 3; }
  div.product .related {
    grid-column: 6 / 11;
    grid-row: 2 / 3; }
  div.product .up-sells {
    grid-column: 1 / 11;
    grid-row: 3 / 4; }

@media (max-width: 767px) {
  .summary {
    margin-bottom: 3rem; } }

.summary .product_title {
  margin-top: 0;
  padding-top: 0;
  color: #151515;
  font-size: 1.5rem !important;
  font-weight: bold;
  margin-bottom: 0rem; }
  @media (max-width: 575px) {
    .summary .product_title {
      font-size: 1.5rem !important;
      color: #008cd6; } }

.summary .price {
  font-size: 1.5rem !important; }
  @media (max-width: 575px) {
    .summary .price {
      font-size: 1.2rem !important; } }

.summary .screen-reader-text {
  display: none; }

.summary .cart.variations_form {
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 1rem; }

.summary .cart {
  display: flex;
  align-items: center;
  margin-bottom: 2rem; }

.summary .cart .qty {
  padding: 16px 10px;
  font-size: 1.3rem;
  text-align: center; }
  @media (max-width: 575px) {
    .summary .cart .qty {
      padding: 10px 5px;
      width: 80px; } }

.summary .single_add_to_cart_button {
  margin-left: 1rem; }

.summary .woocommerce-variation-add-to-cart {
  display: flex;
  justify-content: flex-start;
  align-items: center; }

.product_meta {
  font-size: 0.8rem;
  margin-bottom: 1rem; }

.summary .price {
  margin: 0 0 1rem;
  font-weight: normal;
  color: #151515;
  font-size: 2.0rem; }
  @media (max-width: 575px) {
    .summary .price {
      border: none;
      margin: 0 0 1rem;
      padding: 0; } }
  @media (max-width: 390px) {
    .summary .price {
      margin: 0 0 .5rem; } }

.summary .woocommerce-product-details__short-description {
  margin-bottom: 1.4rem;
  font-size: 1rem;
  color: #323232; }
  @media screen and (max-width: 575px) {
    .summary .woocommerce-product-details__short-description {
      font-size: 0.85rem; } }

.summary table.variations {
  margin-bottom: 1rem; }
  .summary table.variations label {
    font-weight: bold;
    text-transform: uppercase;
    font-size: 0.85rem;
    letter-spacing: 1px;
    color: #151515; }
  .summary table.variations .reset_variations {
    font-size: 12px; }

.vi-wpvs-variation-wrap-image .vi-wpvs-option-wrap {
  padding: 2px !important;
  border-radius: 0 !important;
  width: 70px !important;
  height: 70px !important; }

.product_meta > span {
  display: block; }

.product_meta a {
  color: #151515;
  text-decoration: underline; }

.coleccion {
  margin-bottom: 2rem; }
  .coleccion__item {
    display: grid;
    grid-template-columns: 60px 50px auto 70px;
    grid-gap: 1rem;
    padding: 0.5rem 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    margin-bottom: 4px; }
  .coleccion__foto img {
    width: 50px;
    height: auto;
    border-radius: 2px; }
  .coleccion__quantity label {
    display: none; }
  .coleccion__quantity .quantity input {
    width: 100%;
    padding: 0.75rem 0.50rem !important;
    border-radius: 3px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    color: #323232;
    min-width: 50px; }
  .coleccion__nombre .precio-metro {
    font-size: 0.8rem;
    background-color: #ababab;
    color: rgba(0, 0, 0, 0.6);
    padding: 1px 4px;
    border-radius: 3px; }
  .coleccion__label label {
    display: flex;
    margin-bottom: 3px; }
  .coleccion__label label a {
    font-size: 13px;
    font-weight: 400;
    color: #151515; }
  .coleccion__price {
    text-align: right;
    font-size: 1rem;
    color: #00beea;
    font-weight: 700; }
    .coleccion__price .stock {
      margin: 0;
      font-size: 0.85rem;
      color: #323232; }

@media (max-width: 767px) {
  .woocommerce-tabs {
    margin-bottom: 2rem; } }

.woocommerce-tabs .wc-tab h2:nth-of-type(1) {
  margin-top: 0;
  font-weight: bold;
  margin-bottom: 2rem;
  text-transform: uppercase;
  font-size: 1rem;
  letter-spacing: 1px; }
  @media (max-width: 767px) {
    .woocommerce-tabs .wc-tab h2:nth-of-type(1) {
      display: none; } }

.woocommerce-tabs .wc-tab {
  background-color: white;
  padding: 0rem;
  padding-top: 1.5rem; }
  .woocommerce-tabs .wc-tab h3, .woocommerce-tabs .wc-tab h4, .woocommerce-tabs .wc-tab p, .woocommerce-tabs .wc-tab ul, .woocommerce-tabs .wc-tab ol {
    margin-bottom: 1.5rem; }
  .woocommerce-tabs .wc-tab h3 {
    font-weight: bold;
    font-size: 1.4rem; }
  @media screen and (max-width: 575px) {
    .woocommerce-tabs .wc-tab {
      padding: 1rem;
      padding-left: 0;
      padding-right: 0; } }

.woocommerce-tabs .tabs {
  padding-left: 0;
  list-style: none;
  display: flex;
  border-bottom: 1px solid #151515; }
  .woocommerce-tabs .tabs li {
    margin-right: 1.5rem; }
  .woocommerce-tabs .tabs li a {
    font-size: 1.0rem;
    font-weight: normal;
    text-transform: uppercase;
    padding-bottom: 0.8rem !important;
    color: #323232;
    font-weight: bold;
    display: block;
    position: relative; }
    @media (max-width: 767px) {
      .woocommerce-tabs .tabs li a {
        font-size: 0.8rem; } }
    .woocommerce-tabs .tabs li a:hover {
      color: #00beea; }
    .woocommerce-tabs .tabs li a:hover::after {
      content: '';
      width: 100%;
      height: 4px;
      margin-top: 10px;
      background-color: #00beea;
      display: block;
      position: absolute; }
  .woocommerce-tabs .tabs li.active a {
    color: #00beea;
    position: relative; }
    .woocommerce-tabs .tabs li.active a::after {
      content: '';
      width: 100%;
      height: 4px;
      margin-top: 10px;
      background-color: #00beea;
      display: block;
      position: absolute; }

.comment-form-email label,
.comment-form-author label {
  display: block; }

.wp-comment-cookies-consent label {
  font-size: 0.85rem !important; }

.woocommerce-product-attributes {
  width: 100%; }
  @media screen and (max-width: 575px) {
    .woocommerce-product-attributes-item {
      font-size: .8rem; } }
  .woocommerce-product-attributes-item__label {
    background-color: #f7f7f7;
    width: 25%;
    text-align: right;
    padding-right: 1rem; }
    @media screen and (max-width: 575px) {
      .woocommerce-product-attributes-item__label {
        padding-right: 0;
        text-align: center; } }
  .woocommerce-product-attributes-item__value {
    padding-left: 1rem;
    background-color: rgba(0, 0, 0, 0.05); }
    @media screen and (max-width: 575px) {
      .woocommerce-product-attributes-item__value {
        padding-left: 0;
        text-align: center; } }

.woocommerce-product-gallery {
  position: relative; }
  .woocommerce-product-gallery__trigger {
    position: absolute;
    top: 2rem;
    right: 2rem;
    z-index: 10; }
  @media screen and (min-width: 768px) {
    .woocommerce-product-gallery__wrapper {
      position: sticky;
      top: 75px; } }
  .woocommerce-product-gallery img {
    width: 100%;
    height: auto; }

.woocommerce-product-gallery__wrapper {
  margin: 0; }
  .woocommerce-product-gallery__wrapper .woocommerce-product-gallery__image {
    width: auto;
    display: inline-flex;
    margin-right: 5px; }
    .woocommerce-product-gallery__wrapper .woocommerce-product-gallery__image a img {
      width: 150px;
      height: auto;
      border-radius: 4px; }
      @media (max-width: 767px) {
        .woocommerce-product-gallery__wrapper .woocommerce-product-gallery__image a img {
          width: 70px; } }
  .woocommerce-product-gallery__wrapper .woocommerce-product-gallery__image:first-child {
    width: 100%;
    display: block; }
  .woocommerce-product-gallery__wrapper .woocommerce-product-gallery__image--placeholder {
    width: 100%;
    height: auto; }
  .woocommerce-product-gallery__wrapper .wp-post-image {
    width: 100% !important;
    height: auto;
    border-radius: 5px;
    border: 1px solid #f7f7f7; }

.products.related > h2,
.products.upsells > h2 {
  font-size: 1.0rem;
  font-weight: normal;
  text-transform: uppercase;
  padding-bottom: 0.8rem !important;
  color: #323232;
  font-weight: bold;
  border-bottom: 1px solid #666;
  margin-bottom: 1.2rem !important; }

section.related ul.products {
  display: grid;
  grid-template-columns: 1fr 1fr; }
  section.related ul.products li.product {
    display: block;
    width: 100%;
    height: auto;
    clear: both;
    text-align: center; }
  section.related ul.products .attachment-woocommerce_thumbnail {
    float: left !important;
    width: 100% !important;
    height: auto;
    margin-right: 1rem; }
  section.related ul.products .woocommerce-loop-product__link {
    width: 100%;
    align-items: inherit;
    display: block !important;
    height: auto;
    overflow: hidden; }
  section.related ul.products .woocommerce-loop-product__title {
    font-size: 0.9rem;
    width: auto; }

.garantia {
  display: flex;
  gap: 1rem;
  border-top: 1px solid rgba(0, 0, 0, 0.2);
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  padding: 10px 10px;
  background-color: rgba(0, 0, 0, 0.04);
  margin-bottom: 1rem; }
  .garantia__item {
    display: flex;
    align-items: center;
    text-transform: uppercase;
    font-size: .7rem;
    flex: 1 0 30%; }
  .garantia__ico {
    margin-right: 10px; }
    .garantia__ico svg {
      width: 25px;
      height: 25px; }
    .garantia__ico .bi {
      font-size: 2rem;
      color: #151515; }

.garantia-extras {
  font-size: 0.85rem; }
  .garantia-extras ul {
    list-style: none;
    padding-left: 0; }
  .garantia-extras ul li {
    margin-bottom: 8px; }
    @media (max-width: 575px) {
      .garantia-extras ul li {
        margin-bottom: 6px; } }
  .garantia-extras a {
    color: #151515;
    border-bottom: 1px solid;
    font-weight: normal; }
  .garantia-extras .bi {
    margin-right: 3px; }
  .garantia-extras a.verde-whatsapp {
    color: #529656; }
    @media (max-width: 575px) {
      .garantia-extras a.verde-whatsapp {
        background-color: #529656;
        color: white;
        display: inline-block;
        border-radius: 50px;
        padding: 0 8px;
        text-decoration: none; } }

#review_form .comment-reply-title {
  font-size: 0.85rem;
  margin-bottom: 1rem;
  display: block; }

.product .star-rating span:before,
.star-rating::before {
  color: #ff9500 !important;
  border-color: #ff9500 !important; }

.woocommerce-product-rating .woocommerce-review-link {
  font-size: 0.85rem;
  color: #151515; }

#submit_bizum_payment_form,
#submit_redsys_payment_form {
  font-size: 0.9rem;
  background-color: #00beea;
  color: white;
  margin-right: 1rem;
  box-shadow: none;
  width: auto !important;
  padding: .85rem 1.5rem;
  border: 2px;
  border-radius: 50px;
  letter-spacing: 1px;
  cursor: pointer;
  transition: 0.2s all ease-in-out;
  display: inline-flex;
  align-items: center;
  display: inline-flex !important; }
  @media (max-width: 575px) {
    #submit_bizum_payment_form,
    #submit_redsys_payment_form {
      padding: 0.75rem 1.2rem; } }
  @media (max-width: 390px) {
    #submit_bizum_payment_form,
    #submit_redsys_payment_form {
      padding: 0.70rem 1.0rem;
      font-size: .8rem; } }
  #submit_bizum_payment_form .bi,
  #submit_redsys_payment_form .bi {
    margin-left: 5px; }

.button.cancel {
  background-color: #ababab;
  color: #323232; }

.submit,
.button {
  font-size: 0.9rem;
  background-color: #00beea;
  color: white !important;
  display: inline;
  box-shadow: none;
  padding: .85rem 1.5rem;
  border: 2px;
  border-radius: 50px;
  letter-spacing: 1px;
  cursor: pointer;
  transition: 0.2s all ease-in-out;
  display: inline-flex;
  align-items: center; }
  @media (max-width: 575px) {
    .submit,
    .button {
      padding: 0.75rem 1.2rem; } }
  @media (max-width: 390px) {
    .submit,
    .button {
      padding: 0.70rem 1.0rem;
      font-size: .8rem; } }
  .submit .bi,
  .button .bi {
    margin-left: 5px; }
  .submit:hover,
  .button:hover {
    box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.15);
    transition: 0.2s all ease-in-out;
    background-color: #008cd6 !important;
    color: #fff; }

.single_add_to_cart_button {
  padding: 1.1rem 2.75rem 1rem !important;
  display: flex;
  align-items: center;
  height: auto;
  font-size: 1.1rem !important;
  letter-spacing: 0; }
  @media (max-width: 575px) {
    .single_add_to_cart_button {
      font-size: 1rem;
      padding: 1.3rem 1.5rem !important; } }
  @media (max-width: 575px) {
    .single_add_to_cart_button::before {
      font-size: 1.0rem;
      margin-right: .6rem; } }
  .single_add_to_cart_button:hover {
    background-color: #e18300 !important; }

form.cart button[type="submit"]:not(#qib_id):not(_):not(_) {
  height: auto !important; }

.single_add_to_cart_button.disabled {
  background-color: #84e8ff;
  cursor: not-allowed; }

.checkout-button,
.single_add_to_cart_button {
  background-color: #00beea; }
  .checkout-button:hover,
  .single_add_to_cart_button:hover {
    background-color: #e18300;
    color: white; }

textarea,
select,
input,
.qty {
  padding: 12px 12px;
  border-radius: 5px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  font-size: 0.85rem;
  color: #323232;
  box-shadow: inset 1px 1px 3px 0px rgba(0, 0, 0, 0.15); }
  @media (max-width: 575px) {
    textarea,
    select,
    input,
    .qty {
      font-size: 0.85rem;
      padding: 11px 11px; } }

.qty {
  text-align: center;
  width: 80px; }

.shop_table .qty {
  width: 70px; }

select {
  background: #fff url("../assets/ico/ico-arrow-down.svg") no-repeat right center;
  border: 1px solid rgba(0, 0, 0, 0.2);
  background-position: right 15px top 14px;
  cursor: pointer;
  /* Hide default dropdown arrow */
  appearance: none;
  transition: 0.2s all ease-in-out; }
  select.orderby {
    padding: 8px 10px;
    background-position: right 10px top 7px;
    box-shadow: none; }
  select:hover {
    color: #008cd6;
    transition: 0.2s all ease-in-out; }

.selection .select2-selection {
  height: 50px !important;
  border: 1px solid rgba(0, 0, 0, 0.2) !important;
  box-shadow: inset 1px 1px 3px 0px rgba(0, 0, 0, 0.15); }

.selection .select2-selection__rendered {
  padding: 10px 14px; }

.selection .select2-selection__arrow {
  padding: 10px 12px;
  margin-top: 12px;
  margin-right: 5px; }

.selection .select2-selection__arrow b {
  border-width: 6px 6px 0 6px; }

.qib-container .minus {
  border-radius: 4px 0 0 4px !important; }

.qib-container .plus {
  border-radius: 0 4px 4px 0 !important; }

/**
	 * Star ratings
	 */
.star-rating {
  float: right;
  overflow: hidden;
  position: relative;
  height: 1em;
  line-height: 1;
  font-size: 1em;
  width: 5.4em;
  font-family: "WooCommerce"; }
  .star-rating::before {
    content: "\e020\e020\e020\e020\e020";
    float: left;
    top: 0;
    left: 0;
    position: absolute; }
  .star-rating span {
    overflow: hidden;
    float: left;
    top: 0;
    left: 0;
    position: absolute;
    padding-top: 1.5em; }
  .star-rating span::before {
    content: "\e021\e021\e021\e021\e021";
    top: 0;
    position: absolute;
    left: 0; }

.woocommerce-product-rating {
  line-height: 2;
  display: block; }
  .woocommerce-product-rating .star-rating {
    margin: 0.5em 4px 0 0;
    float: left; }

.products .star-rating {
  display: block;
  margin: 0 0 0.5em;
  float: none; }

.hreview-aggregate .star-rating {
  margin: 10px 0 0; }

#review_form #respond {
  position: static;
  margin: 0;
  width: auto;
  padding: 0;
  background: transparent none;
  border: 0; }
  #review_form #respond p {
    margin: 0 0 10px; }
  #review_form #respond .form-submit input {
    left: auto; }
  #review_form #respond textarea {
    box-sizing: border-box;
    width: 100%; }

p.stars a {
  position: relative;
  height: 1em;
  width: 1em;
  text-indent: -999em;
  display: inline-block;
  text-decoration: none; }
  p.stars a::before {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 1em;
    height: 1em;
    line-height: 1;
    font-family: "WooCommerce";
    content: "\e021";
    text-indent: 0; }
  p.stars a:hover ~ a::before {
    content: "\e021"; }

p.stars:hover a::before {
  content: "\e020"; }

p.stars.selected a.active::before {
  content: "\e020"; }

p.stars.selected a.active ~ a::before {
  content: "\e021"; }

p.stars.selected a:not(.active)::before {
  content: "\e020"; }

@font-face {
  font-family: 'WooCommerce';
  src: url("../fonts/WooCommerce.eot?jj1bj4");
  src: url("../fonts/WooCommerce.eot?jj1bj4#iefix") format("embedded-opentype"), url("../fonts/WooCommerce.ttf?jj1bj4") format("truetype"), url("../fonts/WooCommerce.woff?jj1bj4") format("woff"), url("../fonts/WooCommerce.svg?jj1bj4#WooCommerce") format("svg");
  font-weight: normal;
  font-style: normal; }

[class^="wcicon-"], [class*=" wcicon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'WooCommerce' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.wcicon-storefront:before {
  content: "\e900"; }

.wcicon-ccv:before {
  content: "\e604"; }

.wcicon-virtual:before {
  content: "\e000"; }

.wcicon-up-down:before {
  content: "\e022"; }

.wcicon-reports:before {
  content: "\e023"; }

.wcicon-refresh:before {
  content: "\e031"; }

.wcicon-navigation:before {
  content: "\e032"; }

.wcicon-status-fill:before {
  content: "\e03c"; }

.wcicon-contract:before {
  content: "\e004"; }

.wcicon-downloadable:before {
  content: "\e001"; }

.wcicon-plus:before {
  content: "\e007"; }

.wcicon-simple:before {
  content: "\e006"; }

.wcicon-on-hold:before {
  content: "\e033"; }

.wcicon-external:before {
  content: "\e034"; }

.wcicon-contract-2:before {
  content: "\e036"; }

.wcicon-expand-2:before {
  content: "\e035"; }

.wcicon-phone:before {
  content: "\e037"; }

.wcicon-user:before {
  content: "\e038"; }

.wcicon-status:before {
  content: "\e039"; }

.wcicon-status-pending:before {
  content: "\e012"; }

.wcicon-status-cancelled:before {
  content: "\e013"; }

.wcicon-west:before {
  content: "\e02f"; }

.wcicon-south:before {
  content: "\e02e"; }

.wcicon-mail:before {
  content: "\e02d"; }

.wcicon-inventory:before {
  content: "\e02c"; }

.wcicon-attributes:before {
  content: "\e02b"; }

.wcicon-north:before {
  content: "\e02a"; }

.wcicon-east:before {
  content: "\e029"; }

.wcicon-note:before {
  content: "\e028"; }

.wcicon-windows:before {
  content: "\e027"; }

.wcicon-user2:before {
  content: "\e026"; }

.wcicon-search-2:before {
  content: "\e025"; }

.wcicon-search:before {
  content: "\e024"; }

.wcicon-star-empty:before {
  content: "\e021"; }

.wcicon-share:before {
  content: "\e030"; }

.wcicon-phone-fill:before {
  content: "\e03b"; }

.wcicon-woo:before {
  content: "\e03d"; }

.wcicon-user-fill:before {
  content: "\e03a"; }

.wcicon-grouped:before {
  content: "\e002"; }

.wcicon-status-refunded:before {
  content: "\e014"; }

.wcicon-status-completed:before {
  content: "\e015"; }

.wcicon-variable:before {
  content: "\e003"; }

.wcicon-expand:before {
  content: "\e005"; }

.wcicon-status-failed:before {
  content: "\e016"; }

.wcicon-check:before {
  content: "\e017"; }

.wcicon-right:before {
  content: "\e008"; }

.wcicon-up:before {
  content: "\e009"; }

.wcicon-query:before {
  content: "\e018"; }

.wcicon-down:before {
  content: "\e00a"; }

.wcicon-truck-1:before {
  content: "\e019"; }

.wcicon-left:before {
  content: "\e00b"; }

.wcicon-truck-2:before {
  content: "\e01a"; }

.wcicon-image:before {
  content: "\e00c"; }

.wcicon-globe:before {
  content: "\e01b"; }

.wcicon-link:before {
  content: "\e00d"; }

.wcicon-gear:before {
  content: "\e01c"; }

.wcicon-calendar:before {
  content: "\e00e"; }

.wcicon-cart:before {
  content: "\e01d"; }

.wcicon-processing:before {
  content: "\e00f"; }

.wcicon-card:before {
  content: "\e01e"; }

.wcicon-view:before {
  content: "\e010"; }

.wcicon-stats:before {
  content: "\e01f"; }

.wcicon-status-processing:before {
  content: "\e011"; }

.wcicon-star-full:before {
  content: "\e020"; }

.wcicon-coupon:before {
  content: "\e600"; }

.wcicon-limit:before {
  content: "\e601"; }

.wcicon-restricted:before {
  content: "\e602"; }

.wcicon-edit:before {
  content: "\e603"; }

.woocommerce-notices-wrapper {
  display: block;
  padding-top: 1rem;
  margin-bottom: 3rem; }
  .woocommerce-notices-wrapper:empty {
    margin-bottom: 0;
    padding: 0; }
  .woocommerce-notices-wrapper .woocommerce-error li {
    color: red; }
  .woocommerce-notices-wrapper .woocommerce-message {
    display: block;
    padding: 1rem;
    background-color: white;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);
    border-radius: 0.5rem;
    font-size: 1.2rem;
    text-align: center;
    font-weight: bold;
    overflow: hidden; }
    @media (max-width: 575px) {
      .woocommerce-notices-wrapper .woocommerce-message {
        display: block;
        font-size: 0.8rem;
        text-align: left;
        background-color: #faffd0;
        border: 1px solid #b5bb84;
        padding: 1.0rem;
        box-shadow: 0 0 20px rgba(0, 0, 0, 0.2); } }
    .woocommerce-notices-wrapper .woocommerce-message a.button {
      text-align: center; }
      @media (max-width: 575px) {
        .woocommerce-notices-wrapper .woocommerce-message a.button {
          float: left;
          display: inline-block;
          padding: .85rem 1rem;
          width: 120px;
          height: auto; } }
    @media (max-width: 575px) {
      .woocommerce-notices-wrapper .woocommerce-message a.restore-item {
        height: auto;
        display: inline; } }
  .woocommerce-notices-wrapper .button {
    display: inline-block;
    background-color: #008cd6;
    margin-right: 2rem;
    font-weight: normal; }
    @media (max-width: 575px) {
      .woocommerce-notices-wrapper .button {
        width: 230px;
        margin-right: 1rem; } }
    .woocommerce-notices-wrapper .button:hover {
      color: white; }

.woocommerce-NoticeGroup-checkout .woocommerce-error li {
  color: #880f0f; }

.page-id-7
.woocommerce {
  display: grid;
  grid-template-columns: 3.3fr 2fr;
  grid-column-gap: 2rem;
  grid-row-gap: 0; }
  @media (max-width: 991px) {
    .page-id-7
.woocommerce {
      display: block; } }
  .page-id-7
.woocommerce .woocommerce-notices-wrapper {
    grid-column: 1 / 3; }

.cart_totals,
.shop_table {
  width: 100%; }

/* Tabla del carrito de la compra CELDAS INDIVIDUALES */
.woocommerce-cart-form {
  margin-bottom: 2rem; }
  .woocommerce-cart-form .shop_table {
    width: 100%;
    box-shadow: none !important; }
  .woocommerce-cart-form .product-remove {
    width: 40px;
    font-size: 1.2rem;
    text-align: center; }
    @media (max-width: 767px) {
      .woocommerce-cart-form .product-remove::before {
        display: none; } }
  .woocommerce-cart-form .product-remove a {
    font-size: 1.5rem;
    color: rgba(129, 13, 13, 0.774) !important; }
  .woocommerce-cart-form .product-thumbnail {
    display: flex;
    align-items: center;
    height: 100%; }
  .woocommerce-cart-form .product-thumbnail a {
    display: flex; }
  .woocommerce-cart-form .product-thumbnail img {
    width: 50px;
    height: 50px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 4px; }
  .woocommerce-cart-form .product-name {
    width: 50%;
    text-align: left;
    font-size: 1.0rem;
    font-weight: bold;
    line-height: 1rem !important; }
    .woocommerce-cart-form .product-name .variation {
      display: grid;
      grid-template-columns: 1fr 3fr;
      gap: 5px 10px; }
      @media screen and (max-width: 767px) {
        .woocommerce-cart-form .product-name .variation {
          grid-template-columns: 3fr 1fr; } }
      .woocommerce-cart-form .product-name .variation dt {
        margin-right: 5px;
        font-weight: normal;
        margin-bottom: 3px; }
      .woocommerce-cart-form .product-name .variation dd {
        margin-left: 0;
        margin-right: 5px;
        margin-bottom: 3px; }
        .woocommerce-cart-form .product-name .variation dd p {
          margin: 0;
          display: inline-flex; }
  .woocommerce-cart-form .product-price {
    text-align: right; }
  .woocommerce-cart-form .product-quantity {
    text-align: right; }
    .woocommerce-cart-form .product-quantity .qty {
      padding: 9px 2px; }
  .woocommerce-cart-form .product-subtotal {
    font-weight: bold;
    text-align: right; }
    .woocommerce-cart-form .product-subtotal .tax_label {
      font-size: 11px !important;
      font-weight: normal;
      display: block; }
  .woocommerce-cart-form thead tr th {
    height: 3rem;
    line-height: 3rem;
    font-size: 0.8rem !important;
    text-transform: uppercase; }

/* Tabla del carrito de la compra -------------------------- */
.woocommerce-cart-form table.shop_table {
  background-color: white;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);
  padding: 2rem;
  border-radius: 0.33333rem; }
  @media (max-width: 575px) {
    .woocommerce-cart-form table.shop_table {
      padding: 1.0rem; } }
  @media (max-width: 767px) {
    .woocommerce-cart-form table.shop_table thead {
      display: none; } }
  @media (max-width: 767px) {
    .woocommerce-cart-form table.shop_table tbody tr {
      display: flex;
      flex-direction: column; } }
  @media (max-width: 767px) {
    .woocommerce-cart-form table.shop_table tbody td {
      display: block;
      width: 100%;
      text-align: right;
      font-size: .9rem; } }
  @media (max-width: 767px) {
    .woocommerce-cart-form table.shop_table tbody td.product-thumbnail {
      display: none; } }
  @media (max-width: 767px) {
    .woocommerce-cart-form table.shop_table .cart_item {
      border: 0px solid rgba(0, 0, 0, 0.1);
      border-radius: 3px;
      padding: 5px 5px 4px 5px;
      margin-bottom: 10px; } }
  .woocommerce-cart-form table.shop_table .cart_item td {
    border-bottom: 2px solid white;
    background-color: rgba(0, 0, 0, 0.03); }
  .woocommerce-cart-form table.shop_table td {
    padding: 5px 9px;
    vertical-align: middle;
    line-height: 1.5em; }
  .woocommerce-cart-form table.shop_table td a {
    color: #008cd6; }
  .woocommerce-cart-form table.shop_table th {
    font-weight: 700;
    padding: 7px 9px;
    line-height: 1.5em; }
  .woocommerce-cart-form table.shop_table .actions {
    padding: 1rem 0 0 0; }
    @media (max-width: 575px) {
      .woocommerce-cart-form table.shop_table .actions::before {
        display: none; } }
    .woocommerce-cart-form table.shop_table .actions .button {
      background-color: #ababab;
      color: #323232; }
    .woocommerce-cart-form table.shop_table .actions .button[disabled] {
      cursor: not-allowed;
      box-shadow: none;
      color: white; }

.coupon label {
  display: none; }

@media (max-width: 767px) {
  .coupon {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 1rem; } }

@media (max-width: 767px) {
  .coupon input {
    margin-right: 10px;
    width: 50%; } }

/* Atributos en tabla para responsive --------------- */
@media (max-width: 767px) {
  .woocommerce table.shop_table_responsive tr td::before,
  .woocommerce-page table.shop_table_responsive tr td::before {
    content: attr(data-title) ": ";
    font-weight: 400;
    float: left; } }

.cart_totals table.shop_table {
  border: 1px solid rgba(0, 0, 0, 0.1);
  margin: 0 -1px 24px 0;
  text-align: left;
  width: 100%;
  border-collapse: separate;
  border-radius: 5px; }

.woocommerce table.shop_table .screen-reader-text {
  display: none; }

.update_cart {
  float: right; }

.cart-collaterals {
  width: 100%;
  display: flex;
  justify-content: right; }

/* CARRITO TOTALES Y TRANSPORTE ------------------- */
.cart_totals {
  display: block;
  background-color: white;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);
  padding: 2rem;
  border-radius: 0.33333rem; }
  @media (max-width: 575px) {
    .cart_totals {
      padding: 1.0rem; } }
  @media (max-width: 767px) {
    .cart_totals {
      width: 100%;
      font-size: .9rem !important; } }
  .cart_totals h2 {
    font-size: 1.5rem !important; }
  .cart_totals .shop_table {
    border: 1px solid rgba(0, 0, 0, 0.05);
    margin-bottom: 2rem;
    border-radius: 3px; }
  .cart_totals tbody tr td,
  .cart_totals tbody tr th {
    padding: 12px 12px !important; }
  .cart_totals tbody tr td {
    border: 1px solid rgba(0, 0, 0, 0.05); }
    .cart_totals tbody tr td::before {
      display: none; }
  .cart_totals tbody tr th {
    font-size: 0.85rem;
    text-align: right;
    width: 25%; }
    @media (max-width: 767px) {
      .cart_totals tbody tr th {
        width: 25%; } }
  .cart_totals tbody tr th:nth-child(1) {
    background-color: rgba(0, 0, 0, 0.05); }
  .cart_totals h2 {
    margin-top: 0; }
  .cart_totals .checkout-button {
    display: block;
    font-size: 1.3rem;
    text-align: center;
    padding-top: 1.2rem !important;
    padding-bottom: 1.2rem !important; }

.woocommerce-shipping-methods {
  padding-left: 0;
  list-style: none;
  margin-top: 0;
  margin-bottom: 5px !important; }
  .woocommerce-shipping-methods li label {
    font-weight: bold; }
  .woocommerce-shipping-methods li label span {
    font-weight: normal; }
  .woocommerce-shipping-methods li .betrs_option_desc {
    font-size: 0.85rem; }

.woocommerce #content table.cart td.actions,
.woocommerce table.cart td.actions,
.woocommerce-page #content table.cart td.actions,
.woocommerce-page table.cart td.actions {
  text-align: right; }

.woocommerce #content table.cart td.actions .coupon,
.woocommerce table.cart td.actions .coupon,
.woocommerce-page #content table.cart td.actions .coupon,
.woocommerce-page table.cart td.actions .coupon {
  float: left; }

.woocommerce-form-coupon-toggle {
  margin-bottom: 1rem; }

.col2-set {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 3rem; }
  @media (max-width: 767px) {
    .col2-set {
      grid-template-columns: 1fr;
      grid-gap: 2rem; } }
  .col2-set .col-1 {
    width: 100% !important; }

#order_review_heading,
.woocommerce-billing-fields h3,
.woocommerce-billing-fields h3 label span {
  font-size: 1.0rem !important;
  margin-top: 0;
  color: #008cd6 !important; }

.woocommerce-address-fields label,
.woocommerce-checkout label {
  display: block;
  margin-bottom: 2px;
  font-weight: 500;
  font-size: 0.9rem; }

.woocommerce-address-fields .woocommerce-input-wrapper,
.woocommerce-checkout .woocommerce-input-wrapper {
  width: 100%; }

.woocommerce-address-fields input,
.woocommerce-address-fields textarea,
.woocommerce-checkout input,
.woocommerce-checkout textarea {
  display: block;
  width: 100%; }

.form-row-wide {
  margin-top: 0 !important; }

@media (min-width: 992px) {
  #billing_phone_field,
  #billing_postcode_field,
  .form-row-first {
    width: 48%;
    display: inline-block;
    margin-right: 4%; } }

@media (min-width: 992px) {
  #billing_email_field,
  #billing_city_field,
  .form-row-last {
    width: 48%;
    display: inline-block; } }

/* checkout ---------------------------------- */
@media (min-width: 992px) {
  form.woocommerce-checkout {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto 1fr;
    column-gap: 3rem;
    row-gap: 1rem; } }

@media (min-width: 992px) {
  form.woocommerce-checkout #customer_details {
    grid-column: 1 / 2;
    grid-row: 1 / 3; } }

@media (min-width: 992px) {
  form.woocommerce-checkout #order_review_heading {
    grid-column: 2 / 3;
    grid-row: 1 / 2; } }

@media (min-width: 992px) {
  form.woocommerce-checkout #order_review {
    grid-column: 2 / 3;
    grid-row: 2 / 3; } }

@media (min-width: 992px) {
  #customer_details.col2-set {
    display: flex;
    flex-direction: column;
    gap: 0; } }

/* checkout ---------------------------------- */
.woocommerce-form__label-for-checkbox .woocommerce-terms-and-conditions-checkbox-text {
  display: inline;
  font-size: 1.1rem; }

.woocommerce-form__label-for-checkbox .woocommerce-form__input-checkbox {
  width: auto;
  margin-right: 10px;
  margin-top: 5px;
  display: inline; }

.woocommerce-table--order-details,
.woocommerce-checkout-review-order .shop_table {
  background-color: white;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);
  padding: 2rem;
  border-radius: 0.33333rem;
  margin-bottom: 3rem; }
  @media (max-width: 575px) {
    .woocommerce-table--order-details,
    .woocommerce-checkout-review-order .shop_table {
      padding: 1.0rem; } }

.woocommerce-table--order-details thead tr th,
.woocommerce-checkout-review-order-table thead tr th {
  text-align: left;
  padding: 9px 12px;
  font-size: 1.3rem; }
  @media (max-width: 767px) {
    .woocommerce-table--order-details thead tr th,
    .woocommerce-checkout-review-order-table thead tr th {
      padding: 5px 8px; } }

.woocommerce-table--order-details tfoot tr th,
.woocommerce-table--order-details tfoot tr td,
.woocommerce-table--order-details tbody tr td,
.woocommerce-checkout-review-order-table tfoot tr th,
.woocommerce-checkout-review-order-table tfoot tr td,
.woocommerce-checkout-review-order-table tbody tr td {
  padding: 9px 12px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  text-align: left; }
  @media (max-width: 767px) {
    .woocommerce-table--order-details tfoot tr th,
    .woocommerce-table--order-details tfoot tr td,
    .woocommerce-table--order-details tbody tr td,
    .woocommerce-checkout-review-order-table tfoot tr th,
    .woocommerce-checkout-review-order-table tfoot tr td,
    .woocommerce-checkout-review-order-table tbody tr td {
      padding: 5px 8px !important; } }

.woocommerce-checkout-review-order-table {
  font-size: 0.9rem; }
  .woocommerce-checkout-review-order-table thead .product-total,
  .woocommerce-checkout-review-order-table thead .product-name {
    font-size: 1.1rem; }

.woocommerce-checkout-payment {
  background-color: #f7f7f7;
  border: 1px solid #ababab;
  padding: 2rem;
  border-radius: 5px; }
  @media (max-width: 767px) {
    .woocommerce-checkout-payment {
      padding: 1rem; } }
  .woocommerce-checkout-payment ul.wc_payment_methods {
    list-style: none;
    padding-left: 0;
    margin-bottom: 2rem !important; }
    .woocommerce-checkout-payment ul.wc_payment_methods li.wc_payment_method {
      padding-right: 1rem; }
    .woocommerce-checkout-payment ul.wc_payment_methods li.wc_payment_method > label {
      font-weight: 500; }
    .woocommerce-checkout-payment ul.wc_payment_methods li.wc_payment_method > label > img {
      margin-left: 10px;
      margin-right: 10px; }
    .woocommerce-checkout-payment ul.wc_payment_methods li.wc_payment_method.payment_method_bizum img {
      width: 80px; }
    .woocommerce-checkout-payment ul.wc_payment_methods .input-radio {
      display: inline-block;
      width: auto;
      margin-right: .5rem; }
    .woocommerce-checkout-payment ul.wc_payment_methods .payment_box {
      width: 100%;
      margin: 10px 1.5rem 10px 1.5rem;
      padding: .5rem 1.0rem;
      font-size: 0.85rem;
      border-radius: 0.5rem;
      background-color: #f7f7f7; }
    .woocommerce-checkout-payment ul.wc_payment_methods label {
      display: inline-flex;
      align-items: center;
      font-weight: bold;
      font-size: 1.0rem; }

.place-order button.button {
  background-color: #00beea !important;
  text-align: center;
  padding: 1.2rem 1.5rem;
  font-size: 1.2rem; }
  @media (max-width: 767px) {
    .place-order button.button {
      display: block !important;
      width: 100% !important; } }
  @media (min-width: 991px) {
    .place-order button.button {
      display: block !important;
      width: 100% !important; } }

.woocommerce-terms-and-conditions-wrapper .woocommerce-privacy-policy-text {
  font-size: .8rem; }

.woocommerce-terms-and-conditions-wrapper a {
  color: #008cd6; }

/* Títulos ------------------------- */
.cart-empty,
.woocommerce-thankyou-order-received,
h2.wc-bacs-bank-details-heading,
h2.woocommerce-order-details__title {
  margin-top: 0;
  padding-top: 1rem;
  font-size: 2.0rem !important;
  color: #008cd6;
  font-weight: bold;
  margin-bottom: 1rem; }
  @media (max-width: 767px) {
    .cart-empty,
    .woocommerce-thankyou-order-received,
    h2.wc-bacs-bank-details-heading,
    h2.woocommerce-order-details__title {
      font-size: 1.3rem !important; } }

.wc-bacs-bank-details-account-name {
  margin-top: 0; }

/* Margin bottoms ------------------------- */
.order_details,
.woocommerce-bacs-bank-details {
  margin-bottom: 3rem !important; }

.order_details,
.wc-bacs-bank-details,
.woocommerce-order-overview {
  list-style: none;
  padding: 0;
  background-color: white;
  padding: 1.5rem 1rem 1rem 1.5rem;
  display: flex;
  font-size: 0.9rem;
  border: 1px solid #323232;
  border-radius: 0.5rem; }
  @media (max-width: 767px) {
    .order_details,
    .wc-bacs-bank-details,
    .woocommerce-order-overview {
      flex-direction: column;
      padding: 1rem; } }
  .order_details li,
  .wc-bacs-bank-details li,
  .woocommerce-order-overview li {
    margin-right: 1.5rem;
    padding-right: 1.5rem;
    border-right: 1px solid #323232; }
    @media (max-width: 767px) {
      .order_details li,
      .wc-bacs-bank-details li,
      .woocommerce-order-overview li {
        border-right: 0; } }
  @media (max-width: 767px) {
    .order_details li:last-child,
    .wc-bacs-bank-details li:last-child,
    .woocommerce-order-overview li:last-child {
      margin-bottom: 0 !important; } }
  .order_details li strong,
  .wc-bacs-bank-details li strong,
  .woocommerce-order-overview li strong {
    display: block; }

/* Order recibed ------------------------- */
.woocommerce-table--order-details {
  display: block !important;
  box-shadow: initial; }

#nxshop_spot_selection {
  padding: .85rem 1.5rem;
  border: 2px;
  border-radius: 50px;
  letter-spacing: 1px;
  cursor: pointer;
  transition: 0.2s all ease-in-out;
  display: inline-flex;
  align-items: center;
  background-color: #00beea;
  color: #fff; }
  @media (max-width: 575px) {
    #nxshop_spot_selection {
      padding: 0.75rem 1.2rem; } }
  @media (max-width: 390px) {
    #nxshop_spot_selection {
      padding: 0.70rem 1.0rem;
      font-size: .8rem; } }
  #nxshop_spot_selection .bi {
    margin-left: 5px; }
  @media (max-width: 767px) {
    #nxshop_spot_selection {
      display: block;
      text-align: center; } }

#nxshop_table_container {
  background-color: white;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);
  padding: 2rem;
  border-radius: 0.33333rem;
  font-size: 0.85rem;
  margin-top: 1rem; }
  @media (max-width: 575px) {
    #nxshop_table_container {
      padding: 1.0rem; } }
  #nxshop_table_container table tbody tr td {
    vertical-align: middle !important; }
    #nxshop_table_container table tbody tr td p {
      margin-bottom: 0; }

.woocommerce-account-fields .create-account {
  padding: 1rem;
  border: 1px dashed #323232;
  border-radius: 1rem;
  color: #00beea; }

.woocommerce-form-login-toggle {
  margin-bottom: 2rem; }

.page-template-page-cart .woocommerce-form-login {
  margin-bottom: 2rem; }
  .page-template-page-cart .woocommerce-form-login > p {
    font-size: 0.85rem; }
  .page-template-page-cart .woocommerce-form-login input {
    display: block; }
  .page-template-page-cart .woocommerce-form-login .form-row {
    display: block;
    width: 100%; }
  .page-template-page-cart .woocommerce-form-login .woocommerce-form__label-for-checkbox {
    display: flex; }

.logged-out.page-template-page-login .woocommerce > h2 {
  display: none; }

.woocommerce-form-login {
  background-color: white;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);
  padding: 2rem;
  border-radius: 0.33333rem;
  max-width: 400px;
  margin: 0 auto; }
  @media (max-width: 575px) {
    .woocommerce-form-login {
      padding: 1.0rem; } }

.woocommerce-form-row label,
.woocommerce-form-login label {
  display: block;
  margin-bottom: 8px;
  font-size: 0.9rem; }

.woocommerce-form-row input,
.woocommerce-form-login input {
  display: block;
  width: 100%; }

.woocommerce-form-row__rememberme,
.woocommerce-form-login__rememberme {
  padding-bottom: 1rem !important; }

.woocommerce-form-row__submit,
.woocommerce-form-login__submit {
  display: block;
  width: 100%; }

.woocommerce-form-row .lost_password,
.woocommerce-form-login .lost_password {
  font-size: 0.85rem;
  text-align: right; }

.page-template-page-login.logged-in .woocommerce {
  display: grid;
  grid-template-columns: 3fr 9fr 1fr;
  grid-gap: 3rem; }
  @media (max-width: 991px) {
    .page-template-page-login.logged-in .woocommerce {
      display: initial; } }

.woocommerce-MyAccount-navigation ul {
  background-color: white;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);
  padding: 2rem;
  border-radius: 0.33333rem;
  list-style: none; }
  @media (max-width: 575px) {
    .woocommerce-MyAccount-navigation ul {
      padding: 1.0rem; } }
  @media (max-width: 991px) {
    .woocommerce-MyAccount-navigation ul {
      display: flex;
      padding: 0;
      background-color: initial;
      margin-top: 0;
      flex-wrap: wrap; } }

.woocommerce-MyAccount-navigation li {
  margin-right: 1rem; }

@media (max-width: 991px) {
  .woocommerce-MyAccount-navigation li a {
    padding: 5px 1rem;
    background-color: #ababab;
    border-radius: 50px; } }

@media (max-width: 767px) {
  .woocommerce-MyAccount-navigation li a {
    font-size: 0.85rem;
    color: #323232; } }

.woocommerce-MyAccount-navigation li.is-active a {
  color: #151515; }
  @media (max-width: 991px) {
    .woocommerce-MyAccount-navigation li.is-active a {
      font-size: 0.85rem;
      color: white;
      background-color: #ff9500; } }

.woocommerce-MyAccount-content .woocommerce-table--order-details {
  display: block; }

.woocommerce-MyAccount-content .woocommerce-Message .button {
  display: inline-block; }

.woocommerce-EditAccountForm .woocommerce-form-row input {
  width: auto; }

.woocommerce-EditAccountForm span em {
  font-size: 0.85rem; }

.account-orders-table {
  font-size: .9rem;
  border-collapse: collapse; }
  @media (max-width: 767px) {
    .account-orders-table thead {
      display: none; } }
  @media (max-width: 767px) {
    .account-orders-table tbody {
      font-size: 0.85rem; } }
  @media (max-width: 767px) {
    .account-orders-table tbody tr {
      display: block;
      display: flex;
      flex-direction: column;
      background-color: white;
      margin-bottom: 1rem;
      padding: 1rem;
      border-radius: 0.33333rem; } }
  .account-orders-table tbody td {
    text-align: center;
    padding: 10px 5px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    border-left: 0;
    border-right: 0; }
    @media (max-width: 767px) {
      .account-orders-table tbody td {
        text-align: right;
        padding: 8px 5px; } }
  .account-orders-table tbody .view {
    padding: 6px 1rem;
    display: block; }
  .account-orders-table .woocommerce-orders-table__cell-order-status {
    font-weight: bold; }
  .account-orders-table .woocommerce-orders-table__cell-order-actions .view {
    display: inline-block; }

div[data-title="Cancelado"] {
  color: #760e0e; }
