// Grid -------------------------------

div.product {
  display: grid;
  grid-template-columns: repeat(10, 1fr);
  grid-auto-rows: auto;
  column-gap: 3rem;
  row-gap: 4rem;

  @media (max-width: 575px) {
    display: flex;
    flex-direction: column;
    row-gap: 1rem;

  }

  .woocommerce-product-gallery {
    grid-column: 1 / 6;
    grid-row: 1 / 2;
    position: relative;
  }

  .summary {
    grid-column: 6 / 10;
    grid-row: 1 / 2;
  }

  .woocommerce-tabs {
    grid-column: 1 / 6;
    grid-row: 2 / 3;
  }

  .related {
    grid-column: 6 / 11;
    grid-row: 2 / 3;
  }

  .up-sells {
    grid-column: 1 / 11;
    grid-row: 3 / 4;
  }
}






.summary {
  @media (max-width: 767px) {
    margin-bottom: 3rem;
  }

  .product_title {
    margin-top: 0;
    padding-top: 0;
    color: $color-gris-04;
    font-size: 1.5rem !important;
    font-weight: bold;
    margin-bottom: 0rem;

    @media (max-width: 575px) {
      font-size: 1.5rem !important;
      color: $color-principal-dark;
    }
  }

  .price {
    font-size: 1.5rem !important;

    @media (max-width: 575px) {
      font-size: 1.2rem !important;
    }
  }

  .screen-reader-text {
    display: none;
  }

  .cart.variations_form {
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 1rem;
  }

  .cart {
    display: flex;
    align-items: center;
    margin-bottom: 2rem;
  }

  .cart .qty {
    padding: 16px 10px;
    font-size: 1.3rem;
    text-align: center;

    @media (max-width: 575px) {
      padding: 10px 5px;
      width: 80px;
    }
  }

  .single_add_to_cart_button {
    margin-left: 1rem;
  }

  .woocommerce-variation-add-to-cart {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
}



// Titulo y Summary -------------------------------


.product_meta {
  font-size: 0.8rem;
  margin-bottom: 1rem;
}



.summary {
  .price {
    margin: 0 0 1rem;
    font-weight: normal;
    color: $color-gris-04;
    font-size: 2.0rem;

    @media (max-width: 575px) {
      border: none;
      margin: 0 0 1rem;
      padding: 0;
    }

    @media (max-width: 390px) {
      margin: 0 0 .5rem;
    }
  }

  .woocommerce-product-details__short-description {
    margin-bottom: 1.4rem;
    font-size: $font-size;
    color: $color-gris-03;
    @media screen and (max-width: 575px) {
      font-size: $font-size-small;
    }
  }

  table.variations {
    margin-bottom: 1rem;
    
    label {
      font-weight: bold;
      text-transform: uppercase;
      font-size: $font-size-min;
      letter-spacing: 1px;
      color: $color-gris-04;
    }

    .reset_variations {
      font-size: 12px;
    }

    // .vi-wpvs-variation-style {
    //   background-color: $color-background;
    //   padding: 5px 10px;
    //   border-radius: 1rem;
    // }
  }
}


.vi-wpvs-variation-wrap-image {
  .vi-wpvs-option-wrap {
    padding: 2px !important;
    border-radius: 0 !important;;
    width: 70px !important;
    height: 70px !important;
  }
}



.product_meta {
  & > span {
    display: block;
  }
  a {
    color: $color-gris-04;
    text-decoration: underline;
  }
}


// Agrupados Colecciones -------------------------------
.coleccion {
  margin-bottom: 2rem;

  &__item {
    display: grid;
    grid-template-columns: 60px 50px auto 70px;
    grid-gap: 1rem;
    padding: 0.5rem 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    margin-bottom: 4px;
  }

  &__foto img {
    width: 50px;
    height: auto;
    border-radius: 2px;
  }

  &__quantity label {
    display: none;
  }

  &__quantity .quantity input {
    width: 100%;
    padding: 0.75rem 0.50rem !important;
    border-radius: 3px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    color: $color-gris;
    min-width: 50px;
  }

  &__nombre .precio-metro {
    font-size: 0.8rem;
    background-color: $color-gris-light;
    color: rgba(0, 0, 0, 0.6);
    padding: 1px 4px;
    border-radius: 3px;
  }

  &__label label {
    display: flex;
    margin-bottom: 3px;
  }

  &__label label a {
    font-size: 13px;
    font-weight: 400;
    color: $color-gris-04;
  }

  &__price {
    text-align: right;
    font-size: 1rem;
    color: $color-principal;
    font-weight: 700;

    .stock {
      margin: 0;
      font-size: $font-size-small;
      color: $color-gris;
    }

    .out-of-stock {}
  }
}



// TABS -------------------------------

.woocommerce-tabs {
  @media (max-width: 767px) {
    margin-bottom: $sp-1;
  }

  .wc-tab h2:nth-of-type(1) {
    margin-top: 0;
    font-weight: bold;
    margin-bottom: 2rem;
    text-transform: uppercase;
    font-size: 1rem;
    letter-spacing: 1px;
    @media (max-width: 767px) {
      display: none;
    }
  }

  .wc-tab {
    background-color: $color-background;
    padding: 0rem;
    padding-top: 1.5rem;
    
    h3, h4, p, ul, ol {
      margin-bottom: 1.5rem;
    }

    h3 {
      font-weight: bold;
      font-size: 1.4rem;
    }


    @media screen and (max-width: 575px) {
      padding: 1rem;
      padding-left: 0;
      padding-right: 0;
    }
  }
}

.woocommerce-tabs .tabs {
  padding-left: 0;
  list-style: none;
  display: flex;
  border-bottom: 1px solid $color-gris-dark;

  li {
    margin-right: 1.5rem;
  }

  li a {
    @include tabs-title;
    display: block;
    position: relative;

    &:hover {
      color: $color-principal;
    }

    &:hover::after {
      content: '';
      width: 100%;
      height: 4px;
      margin-top: 10px;
      background-color: $color-principal;
      display: block;
      position: absolute;
    }
  }

  li.active a {
    color: $color-principal;
    position: relative;

    &::after {
      content: '';
      width: 100%;
      height: 4px;
      margin-top: 10px;
      background-color: $color-principal;
      display: block;
      position: absolute;
    }
  }
}

.comment-form-email,
.comment-form-author {
  label {
    display: block;
  }
}

.wp-comment-cookies-consent {
  label {
    font-size: $font-size-min !important;
  }

}



.woocommerce-product-attributes {
  width: 100%;
  &-item {
    @media screen and (max-width: 575px) {
      font-size: .8rem;
    }
    &__label {
      background-color: $color-gris-01;
      width: 25%;
      text-align: right;
      padding-right: 1rem;
      @media screen and (max-width: 575px) {
        padding-right: 0;
        text-align: center;
      }
    }
    &__value {
      padding-left: 1rem;
      background-color: rgba(0, 0, 0, 0.05);
      @media screen and (max-width: 575px) {
        padding-left: 0;
        text-align: center;
      }
    }
  }
}


// Product Gallery -------------------------------
.woocommerce-product-gallery {
  position: relative;

  &__trigger {
    position: absolute;
    top: 2rem;
    right: 2rem;
    z-index: 10;
  }

  &__wrapper {
    @media screen and (min-width: 768px) {
      position: sticky;
      top: 75px;
    }
    // top: 0;
    // bottom: 0;
    // overflow-y:scroll;
  }

  img {
    width: 100%;
    height: auto;
  }
}


.woocommerce-product-gallery__wrapper {
  margin: 0;

  .woocommerce-product-gallery__image {
    width: auto;
    display: inline-flex;
    margin-right: 5px;

    a img {
      width: 150px;
      height: auto;
      border-radius: 4px;

      @media (max-width: 767px) {
        width: 70px;
      }
    }
  }

  .woocommerce-product-gallery__image:first-child {
    width: 100%;
    display: block;
  }

  .woocommerce-product-gallery__image--placeholder {
    width: 100%;
    height: auto;
  }

  .wp-post-image {
    width: 100% !important;
    height: auto;
    border-radius: 5px;
    border: 1px solid $color-gris-01;
    }

}




// Relacionados -------------------------------

.products.related>h2,
.products.upsells>h2 {
  @include related-header;
  border-bottom: 1px solid #666;
  margin-bottom: 1.2rem !important;
}





// Relacionados -------------------------------
section.related ul.products {
  display: grid;
  grid-template-columns: 1fr 1fr;

  li.product {
    // border-bottom: 1px solid $color-gris-light);
    display: block;
    width: 100%;
    height: auto;
    clear: both;
    text-align: center;
  }

  .attachment-woocommerce_thumbnail {
    float: left !important;
    width: 100% !important;
    height: auto;
    margin-right: 1rem;
  }

  .woocommerce-loop-product__link {
    width: 100%;
    align-items: inherit;
    display: block !important;
    // margin-bottom: 0;
    height: auto;
    overflow: hidden;
  }

  .product__cat {}

  .woocommerce-loop-product__title {
    font-size: 0.9rem;
    width: auto;
  }

  .price {}
}

// Garantia -------------------------------

.garantia {
  // display: grid;
  // grid-template-columns: 1fr 1fr 1fr;
  // grid-gap: 1rem;
  display: flex;
  gap: 1rem;
  border-top: 1px solid rgba(0, 0, 0, 0.2);
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  padding: 10px 10px;
  background-color: rgba(0, 0, 0, 0.04);
  margin-bottom: 1rem;
  // border-radius: 5px;

  &__item {
    display: flex;
    align-items: center;
    text-transform: uppercase;
    font-size: .7rem;
    flex: 1 0 30%;
  }

  &__ico {
    margin-right: 10px;
    svg {
      width: 25px;
      height: 25px;
    }
    .bi {
      font-size: 2rem;
      color: $color-gris-dark;
    }
  }

  &__desc {}

}



.garantia-extras {
  font-size: $font-size-min;
  ul {
    list-style: none;
    padding-left: 0;
  }
  ul li {
    margin-bottom: 8px;
    @media (max-width: 575px) {
      margin-bottom: 6px;
    }
  }
  a {
    color: $color-gris-04;
    border-bottom: 1px solid;
    font-weight: normal;
  }
  .bi {
    margin-right: 3px;
  }
  a.verde-whatsapp {
    color: $color-whatsapp;
    @media (max-width: 575px) {
      background-color: $color-whatsapp;
      color: $color-background;
      display: inline-block;
      border-radius: 50px;
      padding: 0 8px;
      text-decoration: none;
    }
  }
}








// Prodcut Reviews -------------------------------
.woocommerce-Reviews {
  .woocommerce-Reviews-title {}

  .woocommerce-noreviews {}
}

#review_form_wrapper {}

#review_form {
  .comment-reply-title {
    font-size: $font-size-min;
    margin-bottom: 1rem;
    display: block;
  }

  .comment-notes {}
}




// Prodcut Reviews -------------------------------
.product .star-rating span:before,
.star-rating::before {
  color: $color-rattings !important;
  border-color: $color-rattings !important;
}

.woocommerce-product-rating .woocommerce-review-link {
  font-size: $font-size-min;
  color: $color-gris-04;
}