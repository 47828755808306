body {
  background-color: $color-background;
  font-size: $font-size;
  color: $color-gris-03;
  font-weight: $font-weight;
}


a {
  text-decoration: none;
  color: $color-principal-dark;
}



.container {
  max-width: 1400px;
  margin: 0 auto;
  padding-left: 20px;
  padding-right: 20px;
  @media screen and (min-width: 768px) and (max-width: 1440px) {
    padding-left: 40px;
    padding-right: 40px;
  }
}

.container--max {
  max-width: 1400px;
}

