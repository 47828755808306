
@mixin input-buttons {
  padding: .85rem 1.5rem;
  border: 2px;
  border-radius: 50px;
  letter-spacing: 1px;
  cursor: pointer;
  transition: $trans;
  display: inline-flex;
  align-items: center;
  @media (max-width: 575px) {
    padding: 0.75rem 1.2rem;
  }
  @media (max-width: 390px) {
    padding: 0.70rem 1.0rem;
    font-size: .8rem;
  }
  .bi {
    margin-left: 5px;
  }
}

@mixin input-buttons-lg {
  padding: 1.30rem 1.5rem;
  border: 2px;
  border-radius: 50px;
  letter-spacing: 1px;
  font-size: 1.2rem;
  cursor: pointer;
  transition: $trans;
}

@mixin input-buttons-sm {
  padding: .55rem 1.2rem;
  letter-spacing: 0px;
  font-size: .9rem;
  cursor: pointer;
  transition: $trans;
}




@mixin input-buttons-hover {
  // transform: translateY(-2px);
  box-shadow: $box-shadow-hover;
  transition: $trans;
}

@mixin related-header {
  font-size: 1.0rem;
  font-weight: normal;
  text-transform: uppercase;
  padding-bottom: 0.8rem !important;
  color: $color-gris;
  font-weight: bold;
}

@mixin tabs-title {
  font-size: 1.0rem;
  font-weight: normal;
  text-transform: uppercase;
  padding-bottom: 0.8rem !important;
  color: $color-gris-03;
  font-weight: bold;
  @media (max-width: 767px) {
    font-size: 0.8rem;
  }
}







@mixin card-cart {
  background-color: $color-background;
  box-shadow: $box-shadow;
  padding: 2rem;
  border-radius: $border-radius-min;
  @media (max-width: 575px) {
    padding: 1.0rem;
  }
}

@mixin subrallado {
  color: $color-background;
  background-color: $color-gris-04;
  border-radius: $border-radius-min;
  transition: $trans;
  padding: 5px 1rem;
  display: inline-block;
  margin-bottom: 10px;
  width: auto;
}



@mixin subrallado-h2 {
  font-size: 1.5rem;
  margin-bottom: .5rem;
  @media (max-width: 575px) {
    margin-bottom: .3rem;
    font-size: 1.2rem;
  }
}

@mixin subrallado-h3 {
  margin-top: 0;
  font-size: 1.1rem;
  @media (max-width: 575px) {
    font-size: 0.9rem;
  }
}