/* SCSS Headers
--------------------------------------------------- */
.h2-header {
  font-size: 2.8rem;
  margin-bottom: 1.5rem;
  font-weight: bold;
  @media screen and (max-width: 767px) {
    font-size: 2rem;
  }
}

.h2-subheader {
  font-size: 1.3rem;
  color: $color-principal-dark;
  @media screen and (max-width: 767px) {
    font-size: 1rem;
  }
}