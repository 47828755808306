.page-id-59,
.page-id-60 {
  // background-color: rgba($color: #000000, $alpha: 0.03);
  background: $color-deg-azul;
  .page-title {
    text-align: center;
    color: $color-background;
  }
  .page-title__breadcrumbs {
    a {
      color: $color-background;
    }
  }
}

.uap-user-page-top-wrapper {
  padding-left: 0 !important;
  padding-right: 0 !important;

  .uap-left-side {
    @media screen and (max-width: 575px) {
      width: 30% !important;
      max-width: 30% !important;
    }
  }
  .uap-middle-side {
    @media screen and (max-width: 575px) {
      width: 70% !important;
      max-width: 70% !important;
    }
  }
}

.uap-ap-theme-3 .uap-user-page-content {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.uap-register-14,
.uap-login-template-13 {
  background-color: $color-background !important;
  padding: 2rem !important;
  box-shadow: 0 0 40px rgba($color: #000000, $alpha: 0.1);
  border-radius: 4px;
  input[type="password"],
  input[type="text"] {
    box-shadow: none;
    border-radius: 4px !important;
  }
  .uap-login-link,
  input[type="submit"] {
    border-radius: 50px !important;
    border-bottom: none !important;
  }

  .uap-form-input-remember {
    height: 10px !important;
    width: 10px !important;
    box-shadow: none !important;
    border-radius: 3px !important;
    margin-left: 0 !important;
  }
  .selection .select2-selection__rendered {
    padding: 5px 14px !important;
  }
}

.uap-login-error {
  text-align: center;
  padding-top: 1rem;
  font-size: $font-size-small;
  color: red;
}
