.hero {
  height: 100vh;
  background-color: #C8C8C8;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  &__cercle {
    width: 50%;
    top: 0;
    bottom: 0;
    position: absolute;

    &--left {
      background: url('../assets/site/bg-hero-left.svg') no-repeat center left;
      background-size: auto 100%;
      left: 0;
      z-index: 1;
      @media screen and (max-width: 991px) {
        background-position: center right;
      }
    }
  
    &--right {
      background: url('../assets/site/bg-hero-right.svg') no-repeat center right;
      background-size: auto 100%;
      right: 0;
      z-index: 2;
      @media screen and (max-width: 991px) {
        background-position: center left;
      }
    }

  }



  &__container {
		position: relative;
    padding-top: 4rem;
		z-index: 10;
		max-width: 90%;
		margin-left: auto;
		margin-right: auto;
		display: flex;
		justify-content: center;
		align-items: center;
  }
}


.owl-carousel {
	max-width: 600px !important;
	margin-left: auto;
	margin-right: auto;

  .owl-nav {
    .owl-prev,
    .owl-next {
      width: 50px;
      height: 55px;
      position: absolute;
      top: 30%;
      display: block !important;
      border: 0px solid black;
      @media screen and (max-width: 991px) {
        
      }
      &::after {
        content: '';
        position: absolute;
        width: 100px;
        height: 1px;
        background-color: $color-background;
        display: block;
        top: 1.8rem;
      }

      &:hover {
        background-color: rgba(0,0,0,.0) !important;
      }

      .ico-chevron {
        width: 55px;
        height: 55px;
        display: block;
        &--left {
          background: url('../assets/ico/ico-chevron-left.svg') no-repeat center center;
        }
        &--right {
          background: url('../assets/ico/ico-chevron-right.svg') no-repeat center center;
        }
      }
    }

    .owl-prev {
      left: -40%;
      @media screen and (max-width: 991px) {
        left: -20%
      }
      &::after {
        left: 4rem;

      }
    }
  
    .owl-next {
      right: -35%;
      @media screen and (max-width: 991px) {
        right: -18%
      }
      &::after {
        right: 3rem;
      }
    }
  
  }


  &__item {
    width: 100%;
  }
	&__picture {
    position: relative;
		> img {
			height: 100%;
      width: auto;
			z-index: 11;
			position: relative;
      filter: drop-shadow(0px 0px  0px rgba(0,0,0,.2));
		}
	}
  &__base {
    position: absolute;
    z-index: 10;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: 70% !important;
    }
  }
  &__description {
    text-align: center;
    h2 {
      text-transform: uppercase;
      font-weight: bold;
      font-size: 2.1rem;
      a {
        color: $color-gris-04;
      }
    }
  }
}





.phome {
  max-width: 1600px;
  margin-left: auto;
  margin-right: auto;
  &__item {
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: 1fr 1fr;
    grid-template-areas: "left right";

    @media screen and (max-width: 767px) {
      grid-template-columns: 1fr;
      grid-template-areas: "right"
                          "left";
    }

    &:nth-child(2n+1) {
      grid-template-areas: "right left";
      @media screen and (max-width: 767px) {
        grid-template-areas: "right"
                            "left";
      }
    }
  }

  &__content {
    padding: 4rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    grid-area: left;
    @media screen and (max-width: 991px) {
      padding: 3rem;
    }
    @media screen and (max-width: 575px) {
      padding: 1rem 1.5rem 3.5rem;
    }
  }

  &__header {
    font-size: 5rem;
    font-weight: bold;
    margin-top: 0;
    margin-bottom: 1.5rem;
    @media screen and (max-width: 1199px) {
      font-size: 3rem;
    }
    @media screen and (max-width: 991px) {
      font-size: 3rem;
    }
    @media screen and (max-width: 575px) {
      font-size: 2rem;
      margin-bottom: 1rem;
    }
    span {
      display: block;
      font-weight: lighter;
      font-size: 70%;
    }
  }

  &__description {
    margin-bottom: 1rem;
    @media screen and (max-width: 575px) {
      margin-bottom: 0;
    }
    p {
      color: $color-gris-03;
      @media screen and (max-width: 767px) {
        font-size: $font-size-min;
      }
    }
  }

  &__actions {
  }

  &__picture {
    grid-area: right;
		margin-bottom: 0;
    img {
      width: 100%;
      height: auto;
    }
  }
}
