.l-header {
  padding: 9rem 0rem 3rem;
  @media screen and (max-width: 575px) {
    padding-top: 6rem;
    padding-bottom: 2rem;
  }
  &__container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 3rem;

    @media screen and (max-width: 575px) {
      grid-template-columns: 1fr;
      gap: 0;
    }
  }

  &__picture {
    display: flex;
    justify-content: center;
    align-items: center;
    @media screen and (max-width: 767px) {
      justify-content: left;
    }
    img {
      width: 80%;
      height: auto;
    }
    @media screen and (max-width: 767px) {
      width: 70%;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &__title {
    font-size: 3rem;
    margin-bottom: 0;
    @media screen and (max-width: 575px) {
      font-size: 1.5rem;
    }
  }

  &__marca {
    font-size: 4.125rem;
    font-weight: bold;
    margin-bottom: 1rem;
    @media screen and (max-width: 575px) {
      font-size: 2rem;
    }
  }

  &__precio {
    font-size: 1.25rem;
    > span {
      font-weight: bold;
      padding-left: 1rem;
    }
  }

  &__duracion {
    margin-bottom: 2rem;
    font-size: 1.25rem;
    @media screen and (max-width: 575px) {
      font-size: $font-size-small;
    }
    mark {
      background-color: #ffe34e;
      border-radius: 3px;
    }
  }
}




.l-description {

  padding: 5rem 0;
  @media screen and (max-width: 575px) {
    padding: 3rem 0;
  }

  &__container {
  }

  &__content {
    text-align: center;
  }

  &__titulo {
    color: $color-principal;
    font-size: 3.1rem;
    @media screen and (max-width: 575px) {
      font-size: 1.2rem;
    }
  }

  &__marca {
    font-size: 5rem;
    font-weight: bold;
    @media screen and (max-width: 575px) {
      font-size: 1.8rem;
    }
  }

  &__descripcion {
    margin-bottom: 2rem;
    width: 80%;
    font-size: 1.2rem;
    margin-left: auto;
    margin-right: auto;
    color: $color-gris-03;
    @media screen and (max-width: 575px) {
      width: 100%;
      font-size: $font-size-small;
    }
  }

  &__actions {
  }
}





.l-extras {
  padding: 4rem 0;
  @media screen and (max-width: 575px) {
    padding: 2rem 0 4rem;
  }
  &__container {
  }

  &__content {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 3rem;
    @media screen and (max-width: 575px) {
      grid-template-columns: 1fr;
      gap: 0;
    }
  }

  &__pic {
    text-align: center;
    img {
      width: 80%;
      height: auto;
      @media screen and (max-width: 575px) {
        width: 70%;
      }
    }
  }
  &__right {
    display: flex;
    justify-content: center;
    flex-direction: column;
  }

  &__marca {
    color: $color-principal;
    font-size: 1.2rem;
  }

  &__titulo {
    font-weight: bold;
    font-size: 2.5;
  }

  &__descripcion {
    font-size: 20px;
    color: $color-gris-03;
    @media screen and (max-width: 575px) {
      font-size: $font-size-small;
    }
  }
}





.l-galeria {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 5px;
  margin-bottom: 5px;
  @media screen and (max-width: 575px) {
    grid-template-columns: repeat(2, 1fr);
  }
  &__item {
    img {
      width: 100%;
      height: auto;
    }
    &:first-child {
      @media screen and (max-width: 575px) {
        grid-column: 1 / 3;
      }
      
    }
  }
}





.cta-landing {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 6rem;
  color: $color-background;
  margin-bottom: 5px;
  @media screen and (max-width: 575px) {
    grid-template-columns: 1fr;
  }
  &__picure {
    @media screen and (max-width: 575px) {
      display: none;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &__comprar {
    font-weight: 700;
    @media screen and (max-width: 575px) {
      display: none;
    }
  }

  &__desc {
    font-size: 2rem;
  }

  &__titulo {
    font-size: 7.6875rem;
    font-weight: bold;
    white-space: nowrap;
    margin-left: -300px;
    position: relative;
    @media screen and (max-width: 575px) {
      font-size: 3rem;
      color: $color-principal;
      position: initial;
      margin-left: 0;
    }
  }

  &__actions {
    display: flex;
    margin-top: 3rem;
    @media screen and (max-width: 575px) {
      margin-top: 1rem;
    }
  }

  &__logo {
    margin-right: 2rem;
    @media screen and (max-width: 575px) {
      display: flex;
      align-items: center;
    }
    img {
      width: 130px;
      height: auto;
      @media screen and (max-width: 575px) {
        width: 100px;
      }
    }
  }

  &__shop {
  }

  &__price {
    margin-bottom: 1rem;
    font-size: 1.5rem;
  }
}
