.boton {
  padding: 0.7rem 1.7rem 0.6rem;
  font-size: 0.9rem;
  font-weight: 500;
  border-radius: 50px;
  display: inline-flex;
  align-items: center;
  text-decoration: none;
  border-style: solid;
  cursor: pointer;
  transition: $trans;
  i {
    display: inline-flex;
    align-items: center;
    margin-right: 10px;
  }
  &:hover {
    transition: $trans;
    transform: translateY(-2px);
    box-shadow: $box-shadow-hover;
  }
}

.boton--sm {
  padding: 0.3rem 0.9rem 0.3rem;
  font-size: 0.85rem;
}
.boton--lg {
  padding: 1rem 2.2rem;
  font-size: 1.1rem;
}


input[disabled=""].boton--principal {
 opacity: 0.5;
 color: $color-background !important;
 transform: initial;
 &:hover {
   box-shadow: initial;
 }
}


.boton--principal {
  color: $color-background;
  background: $color-deg-azul;
  padding: 0.825rem 1.7rem 0.825rem;
  border: none;
  &:hover {
    color: $color-background;
    background: $color-deg-azul;
    text-decoration: none;
  }
}

.boton--principal-outline {
  color: $color-gris-03;
  background-color: initial;
  border-color: $color-principal;
  padding: 0.7rem 1.7rem 0.6rem;
  border-width: 0.125rem;
  &:hover {
    color: $color-background;
    background-color: $color-principal;
    border-color: $color-principal;
    text-decoration: none;
  }
}


.boton--blanco {
  color: $color-gris-03;
  background-color: $color-background;
  border-color: $color-background;
  padding: 0.825rem 1.7rem 0.825rem;
  border: none;
  &:hover {
    color: $color-gris-04;
    text-decoration: none;
  }
}



input[disabled=""].boton--principal {
  background-color: $color-gris-01;
  border-color: $color-gris-01;
  color: $color-gris-02;
}

.boton--outline-gris {
  color: $color-principal;
  background-color: initial;
  border-color: $color-gris-03;
  &:hover {
    color: $color-secundario;
    background-color: $color-gris-03;
    border-color: $color-gris-03;
    text-decoration: none;
  }
}


.boton--secundario {
  color: $color-principal;
  background-color: $color-secundario;
  border-color: $color-secundario;
  &:hover {
    color: $color-background;
    background-color: $color-principal;
    border-color: $color-principal;
    text-decoration: none;
  }
}

.boton--alternativo {
  color: $color-gris-03;
  background-color: $color-alternativo;
  border-color: $color-alternativo;
  &:hover {
    color: $color-gris-04;
    background-color: darken($color-alternativo, 10%);
    border-color: darken($color-alternativo, 10%);
    text-decoration: none;
  }
}


.boton--gris {
  color: $color-gris-03;
  background-color: $color-gris-01;
  border-color: $color-gris-01;
  &:hover {
    color: $color-gris-04;
    background-color: darken($color-gris-01, 10%);
    border-color: darken($color-gris-01, 10%);
    text-decoration: none;
  }
}

