
.cta-home {
  background-color: $color-gris-03;
  color: $color-background;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  &__container {
    padding: 6rem 1.2rem;
    max-width: 600px !important;
    margin-left: auto;
    margin-right: auto;
    @media screen and (max-width: 575px) {
      padding: 3rem 1.2rem;
    }

    h2 {
      font-weight: bold;
    }
    p {
      color: $color-gris-02;
      @media screen and (max-width: 767px) {
        font-size: $font-size-small;
      }
    }
  }
  &__actions {
    margin-top: 2rem;
    .boton {
      @media screen and (max-width: 575px) {
        margin-bottom: 10px;
      }
    }
  }
}


.cta-footer {
  padding: 8rem 0;
  background: $color-deg-azul;
  color: $color-background;
  @media screen and (max-width: 575px) {
    padding: 4rem 0;
  }
  &__container {
    display: flex;
    justify-content: space-evenly;
    @media screen and (max-width: 767px) {
      flex-direction: column;
    }
    h2 {
      font-size: 2rem;
      font-weight: bold;
      @media screen and (max-width: 575px) {
        font-size: 1.5rem;
      }
    }
    p {
      @media screen and (max-width: 575px) {
        font-size: $font-size-min;
      }
    }
  }

  &__left {
    flex: 0 0 30%;
    @media screen and (max-width: 991px) {
      flex: 0 0 40%;
    }
    @media screen and (max-width: 767px) {
      margin-bottom: 3rem;
    }
  }

  &__right {
    flex: 0 0 30%;
    @media screen and (max-width: 991px) {
      flex: 0 0 40%;
    }
  }
}
