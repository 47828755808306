#submit_bizum_payment_form,
#submit_redsys_payment_form {
  font-size: 0.9rem;
  background-color: $color-principal;
  color: $color-background;
  margin-right: 1rem;
  box-shadow: none;
  width: auto !important;
  @include input-buttons;
  display: inline-flex !important;
}

.button.cancel {
  background-color: $color-gris-light;
  color: $color-gris;
}

.submit,
.button {
  font-size: 0.9rem;
  background-color: $color-principal;
  color: $color-background !important;
  display: inline;
  box-shadow: none;
  @include input-buttons;
  &:hover {
    @include input-buttons-hover;
    background-color: $color-principal-dark !important;
    color: #fff;
  }
}





.single_add_to_cart_button {
  padding: 1.1rem 2.75rem 1rem !important;
  display: flex;
  align-items: center;
  height: auto;
  font-size: 1.1rem !important;
  letter-spacing: 0;
  @media (max-width: 575px) {
    font-size: 1rem;
    padding: 1.3rem 1.5rem !important;
  }
  &::before {
    // font-family: 'WooCommerce';
    // content: "\e01d";
    // font-weight: 300;
    // margin-right: 1rem;
    // font-size: 1.3rem;

    // display: inline-block;
    // content: '';
    // background-image: url("data:image/svg+xml,<svg viewBox='0 0 16 16' fill='%23333' xmlns='http://www.w3.org/2000/svg'><path fill-rule='evenodd' d='M8 9.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3z' clip-rule='evenodd'/></svg>");
    // background-repeat: no-repeat;
    // background-size: 1rem 1rem;

    @media (max-width: 575px) {
      font-size: 1.0rem;
      margin-right: .6rem;
    }
  }
  &:hover {
    background-color: $color-alternativo-dark !important;
  }
}

form.cart button[type="submit"]:not(#qib_id):not(_):not(_) {
  height: auto !important;
}


.single_add_to_cart_button.disabled {
  background-color: lighten($color-principal, 30%);
  cursor: not-allowed;
}


.checkout-button,
.single_add_to_cart_button {
  background-color: $color-principal;
  &:hover {
    background-color: $color-alternativo-dark;
    color: $color-background;
  }
}

li.product {

}
