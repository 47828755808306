.woocommerce-notices-wrapper {
  display: block;
  padding-top: 1rem;
  margin-bottom: 3rem;

  &:empty {
    margin-bottom: 0;
    padding: 0;
  }
  .woocommerce-error {
    
    li {
      color: red;
    }
  }
  .woocommerce-message {
    display: block;
    padding: 1rem;
    background-color: $color-background;
    box-shadow: $box-shadow;
    border-radius: $border-radius;
    font-size: 1.2rem;
    text-align: center;
    font-weight: bold;
    overflow: hidden;
    @media (max-width: 575px) {
      display: block;
      font-size: 0.8rem;
      text-align: left;
      background-color: #faffd0;
      border: 1px solid #b5bb84;
      padding: 1.0rem;
      box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
    }
    a.button {
      text-align: center;
      @media (max-width: 575px) {
        float: left;
        display: inline-block;
        padding: .85rem 1rem;
        width: 120px;
        height: auto;
      }
    }
    a.restore-item {
      @media (max-width: 575px) {
        height: auto;
        display: inline;
      }
    }
  }

  .button {
    display: inline-block;
    background-color: $color-principal-dark;
    margin-right: 2rem;
    font-weight: normal;
    @media (max-width: 575px) {
      width: 230px;
      margin-right: 1rem;
    }
    &:hover {
      color: $color-background;
    }
  }
}


.woocommerce-NoticeGroup-checkout .woocommerce-error {
  li {
    color: rgb(136, 15, 15);
  }
}