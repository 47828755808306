$color-principal: #00beea;
$color-principal-dark: rgba(0,140,214,1);
$color-secundario: #f08175;
$color-alternativo: #ff9500;
$color-alternativo-dark: #e18300;

$color-deg-azul: linear-gradient(45deg, rgba(0,190,234,1) 0%, rgba(0,140,214,1) 100%);
$color-deg-azul-hover: linear-gradient(45deg, rgba(0,140,214,1) 0%, rgba(0,140,214,1) 100%);

$color-gris-01: #f7f7f7;
$color-gris-02: #ababab;
$color-gris-03: #323232; 
$color-gris-04: #151515; 

$color-whatsapp: #529656;
$color-rattings: #ff9500;

$color-background: rgba(255, 255, 255, 1);

$font-size-big: 1.2rem;
$font-size: 1rem;
$font-size-small: 0.85rem;
$font-size-smaller: 0.7rem;

$font-weight: 400;

$box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);
$box-shadow-hover: 0 5px 5px 0 rgba(0, 0, 0, 0.15);

$border-radius: $font-size / 2;
$border-radius-min: $font-size / 3;

$trans: 0.2s all ease-in-out;

$sp-1: 2rem;
$sp-2: 4rem;
$sp-3: 8rem;

$typo-principal: "Helvetica Neue", Helvetica, Arial, sans-serif;
$typo-secundaria: "Georgia", serif;




$font-size-min: $font-size-small;
$color-gris-light: $color-gris-02;
$color-gris: $color-gris-03;
$color-gris-dark: $color-gris-04;
$color-gris-bg-dark: $color-gris-04;