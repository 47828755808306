.page {
  &__entry {
    h2, h3, h4, h5 {
      margin-bottom: 1.5rem;
      font-weight: bold;
    }

    h2 {

    }
    h3 {
      color: $color-gris-03;
    }

    p, ul, ol {
      margin-bottom: 1.5rem;
    }
    a {
      color: $color-principal;
    }
  }
}