.logged-out.page-template-page-login .woocommerce>h2 {
  display: none;
}


.woocommerce-form-login {
  @include card-cart;
  max-width: 400px;
  margin: 0 auto;
}

.woocommerce-form-row,
.woocommerce-form-login {
  label {
    display: block;
    margin-bottom: 8px;
    font-size: 0.9rem;
  }

  input {
    display: block;
    width: 100%;
  }

  &__rememberme {
    padding-bottom: 1rem !important;
  }

  &__submit {
    display: block;
    width: 100%;
  }

  .lost_password {
    font-size: $font-size-min;
    text-align: right;
  }
}


.page-template-page-login.logged-in .woocommerce {
  display: grid;
  grid-template-columns: 3fr 9fr 1fr;
  grid-gap: 3rem;

  @media (max-width: 991px) {
    display: initial;
  }
}

.woocommerce-MyAccount-navigation {

  ul {
    @include card-cart;
    list-style: none;

    @media (max-width: 991px) {
      display: flex;
      padding: 0;
      background-color: initial;
      margin-top: 0;
      flex-wrap: wrap;
    }
  }

  li {
    margin-right: 1rem;
  }

  li a {
    @media (max-width: 991px) {
      padding: 5px 1rem;
      background-color: $color-gris-light;
      border-radius: 50px;
    }

    @media (max-width: 767px) {
      font-size: $font-size-min;
      color: $color-gris;
    }
  }
  li.is-active a {
    color: $color-gris-dark;
    @media (max-width: 991px) {
      font-size: $font-size-min;
      color: $color-background;
      background-color: $color-alternativo;
    }
  }
}

.woocommerce-MyAccount-content {
  .woocommerce-table--order-details {
    display: block;
  }
  .woocommerce-Message .button {
    display: inline-block;
  }
}


.woocommerce-EditAccountForm {
  .woocommerce-form-row input {
    width: auto;
  }

  span em {
    font-size: $font-size-min;
  }
}

.account-orders-table {
  font-size: .9rem;
  border-collapse: collapse;
  thead {
    @media (max-width: 767px) {
      display: none;
    }
  }
  tbody {
    @media (max-width: 767px) {
      font-size: $font-size-min;
    }
    tr {
      @media (max-width: 767px) {
        display: block;
        display: flex;
        flex-direction: column;
        background-color: $color-background;
        margin-bottom: 1rem;
        padding: 1rem;
        border-radius: $border-radius-min;
        
      }
    }
    td {
      text-align: center;
      padding: 10px 5px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      border-left: 0;
      border-right: 0;
      @media (max-width: 767px) {
        text-align: right;
        padding: 8px 5px;
      }
    }

    .view {
      padding: 6px 1rem;
      display: block;
    }
  }

  .woocommerce-orders-table__cell-order-status {
    font-weight: bold;
  }
  .woocommerce-orders-table__cell-order-actions .view {
    display: inline-block;
  }
}




div[data-title="Cancelado"] {
  color: rgb(118, 14, 14);
}