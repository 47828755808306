.widget {
  &__title {
    text-transform: uppercase;
    margin-bottom: 1rem;
    font-size: $font-size;
    margin-bottom: 1.5rem;
    letter-spacing: .5px;
    font-weight: bold;
  }
}


.widget--footer {

  p, a {
    font-size: $font-size-small;
  }

  a {
    color: $color-principal;
  }

  ul.menu {
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
    .menu-item {
      a {
        color: $color-gris-02;
      }
    }
  }
}